import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { Trans, useTranslation } from 'next-i18next';
import React, { useContext, useEffect, useMemo } from 'react';

import { useLogin } from '@/hooks/auth/useLogin';
import useSignMultipleTransactions, {
  ALLOWED_TRANSACTIONS,
} from '@/hooks/core/useSignMultipleTransactions';
import useLendInteraction from '@/hooks/interaction/useLendInteraction';
import useLiquidStakingInteraction from '@/hooks/interaction/useLiquidStakingInteraction';
import useLiquidStakingMessageError, {
  OPERATION_KEYS,
} from '@/hooks/liquidStakingApp/useLiquidStakingMessageError';
import useExchangeRate, {
  EXCHANGE_RATE_KEY,
} from '@/hooks/protocol/useExchangeRate';
import useBreakpoint from '@/hooks/useBreakpoint';
import useMediaQuery from '@/hooks/useMediaQuery';

import Hint from '@/components/Hint';

import { boosterSelector } from '@/store/booster';
import { useAppDispatch, useAppSelector } from '@/store/index';
import { liquidStakingAppSelector } from '@/store/liquid-staking-app';
import { openPopup } from '@/store/popup';
import {
  H_TOKEN_DECIMALS,
  hasEnoughEGLDBalanceSelector,
  nativeMarketSelector,
  nativeUserBalanceSelector,
  nextEpochTimeLeftSelector,
  protocolSelector,
  sEgldMarketSelector,
} from '@/store/protocol';
import {
  hasPendingTransactionsSelector,
  TRANSACTION_GROUP_TYPE,
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';

import { hasSplitLiquidStakingStake } from '@/config/envVars';
import { FormContext } from '@/contexts/LiquidStakingFormContext';
import {
  AcceptDeal,
  AmountInput,
  AmountLimitErrors,
  ChooseToken,
  Label,
  Notes,
  SubmitButton,
} from '@/sections/Liquid/App/Form/components';
import {
  ASHSWAP_FORM,
  MAX_AMOUNT,
  SPLIT_AMOUNT,
} from '@/sections/Liquid/App/Form/constants';
import { divideValuePerMax } from '@/sections/Liquid/App/Form/utils';
import { useHintProps } from '@/sections/Liquid/App/global';
import { PrettyToken as ColoredName } from '@/sections/Liquid/Common/PrettyToken';
import { formatNumber, subtractGasFee } from '@/utils/helpers';

export const StakingInputs: React.FC<{
  setColorBooster: (id: boolean) => void;
}> = (props) => {
  const { setColorBooster } = props;
  const dispatch = useAppDispatch();
  const { hasStaked: hasHtmStaked } = useAppSelector(boosterSelector);
  const nativeMarket = useAppSelector(nativeMarketSelector);
  const nativeUserBalance = useAppSelector(nativeUserBalanceSelector);
  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const nextEpochTimeLeft = useAppSelector(nextEpochTimeLeftSelector);
  const { blockchain } = useAppSelector(protocolSelector);
  const sEgldMarket = useAppSelector(sEgldMarketSelector);
  const { minEgldToDelegate, totalFee } = useAppSelector(
    liquidStakingAppSelector,
  );
  const { marketsInteracted, marketsInteractedAmount, controller, markets } =
    useAppSelector(protocolSelector);
  const { isLoggedIn } = useLogin();
  const { signTransactions, isAllowedTransaction, isWhiteListed } =
    useSignMultipleTransactions();
  const { delegate, lendegate } = useLiquidStakingInteraction();
  const { rebalancePortfolio, removeAccountMarket } = useLendInteraction();
  const { t } = useTranslation('liquid-app');
  const defaultHintProps = useHintProps();

  const md = useMediaQuery('(min-width: 768px)');
  const egldToSEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.EGLD,
    EXCHANGE_RATE_KEY.sEGLD,
  );
  const egldToHsEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.EGLD,
    EXCHANGE_RATE_KEY.HsEGLD,
  );

  const minValue = useMemo(
    () => new DefiUtils(minEgldToDelegate).multipliedBy('1.0001').toString(),
    [minEgldToDelegate],
  );

  const {
    formsState: {
      stakingInputs: {
        token,
        changeToken,
        supplyHsEGLd,
        check,
        inputValue,
        onChange,
        annualEarnings,
        estimation,
      },
      operation,
    },
    percentages,
    rewardsTokensAPY,
    boosterRewardsAPY
  } = useContext<any>(FormContext);

  const receivedValue = useMemo(() => {
    const result = { value: '0', formatted: `0 ${token}` };

    if (!inputValue || !new DefiUtils(inputValue).isGreaterThan(0)) {
      return result;
    }

    switch (token) {
      case EXCHANGE_RATE_KEY.sEGLD: {
        const value = new DefiUtils(inputValue)
          .multipliedBy(egldToSEgld)
          .toFixed(sEgldMarket.underlying.decimals, DefiUtils.ROUND_DOWN);

        return { value, formatted: `${formatNumber(value, 6)} ${token}` };
      }

      case EXCHANGE_RATE_KEY.HsEGLD: {
        const value = new DefiUtils(inputValue)
          .multipliedBy(egldToHsEgld)
          .toFixed(H_TOKEN_DECIMALS, DefiUtils.ROUND_DOWN);

        return { value, formatted: `${formatNumber(value, 6)} ${token}` };
      }
    }

    return result;
  }, [
    egldToHsEgld,
    egldToSEgld,
    inputValue,
    sEgldMarket.underlying.decimals,
    token,
  ]);

  const exchangeRate = useMemo(() => {
    switch (token) {
      case EXCHANGE_RATE_KEY.sEGLD: {
        return egldToSEgld;
      }

      case EXCHANGE_RATE_KEY.HsEGLD: {
        return egldToHsEgld;
      }

      default: {
        return '0';
      }
    }
  }, [egldToHsEgld, egldToSEgld, token]);

  const sm = useBreakpoint('sm');

  const exchangeRateTooltip = useMemo(() => {
    const totalTime = blockchain.roundsPerEpoch * 6;
    const timeLeftInSeconds = blockchain.timeLeftInSeconds;
    const tenMinutesInSeconds = 60 * 10;

    if (totalTime - timeLeftInSeconds < tenMinutesInSeconds) {
      return t('liquid-app:tp-12-plus');
    }

    if (token == EXCHANGE_RATE_KEY.sEGLD) {
      return (
        <Trans
          i18nKey='tp-12'
          tOptions={{ nextEpoch: nextEpochTimeLeft, token: 'EGLD' }}
          components={[
            <ColoredName token={EXCHANGE_RATE_KEY.sEGLD} />,
            <span className='text-green' />,
          ]}
          ns='liquid-app'
        />
      );
    } else {
      return (
        <Trans
          i18nKey='tp-15'
          components={[
            <ColoredName token={EXCHANGE_RATE_KEY.HsEGLD} />,
            <ColoredName token={EXCHANGE_RATE_KEY.sEGLD} />,
          ]}
          ns='liquid-app'
        />
      );
    }
  }, [
    blockchain.roundsPerEpoch,
    blockchain.timeLeftInSeconds,
    nextEpochTimeLeft,
    t,
    token,
  ]);

  const notes = useMemo(() => {
    return [
      {
        caption: t('est-annually-earnings'),
        captionTooltip:
          token == EXCHANGE_RATE_KEY.sEGLD ? (
            <Trans
              i18nKey='tp-8'
              components={[<ColoredName token={token} />]}
              ns='liquid-app'
              tOptions={{ token: 'EGLD' }}
            />
          ) : (
            <>
              <Trans
                i18nKey='tp-16'
                components={[<ColoredName token={token} />]}
                ns='liquid-app'
              />
              {supplyHsEGLd ? t('tp-16-plus') : '.'}
            </>
          ),
        tooltipMax:
          token === EXCHANGE_RATE_KEY.HsEGLD
            ? md
              ? 350
              : 283
            : md
            ? 300
            : 291,
        value: `$${formatNumber(annualEarnings, annualEarnings == 0 ? 0 : 3)}`,
      },
      {
        caption: t('receive'),
        captionTooltip: null,
        value: (
          <Hint
            {...defaultHintProps}
            content={receivedValue.value}
            hidden={receivedValue.value == '0'}
          >
            {receivedValue.formatted}
          </Hint>
        ),
      },
      {
        caption: t('exchange-rate'),
        tooltipMax: md ? 204 : 203,
        captionTooltip: exchangeRateTooltip,
        customIcon: (
          <div className='mt-1px inline-block'>
            <svg
              width={11}
              height={11}
              viewBox='0 0 10 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4.99948 6.00009C4.83948 6.00009 4.67948 5.92009 4.58448 5.77509C4.42948 5.54509 4.49448 5.23509 4.72448 5.08009L6.22448 4.08009C6.45448 3.92509 6.76448 3.99009 6.91948 4.22009C7.07448 4.45009 7.00948 4.76009 6.77948 4.91509L5.27948 5.91509C5.19448 5.97009 5.09948 6.00009 5.00448 6.00009H4.99948Z'
                fill='#4AAD7C'
              />
              <path
                d='M4.99947 5.99995C4.84947 5.99995 4.69947 5.92995 4.59947 5.79995L3.09947 3.79995C2.93447 3.57995 2.97947 3.26495 3.19947 3.09995C3.41947 2.93495 3.73447 2.97995 3.89947 3.19995L5.39947 5.19995C5.56447 5.41995 5.51947 5.73495 5.29947 5.89995C5.20947 5.96495 5.10447 5.99995 4.99947 5.99995Z'
                fill='#4AAD7C'
              />
              <path
                d='M5.56 10H4.435C1.99 10 0 8.01 0 5.56V4.435C0 1.99 1.99 0 4.44 0H5.565C8.01 0 10.005 1.99 10.005 4.44V5.565C10.005 8.01 8.015 10.005 5.565 10.005L5.56 10ZM4.44 1C2.545 1 1 2.54 1 4.44V5.565C1 7.46 2.54 9.005 4.44 9.005H5.565C7.46 9.005 9.005 7.465 9.005 5.565V4.44C9.005 2.545 7.465 1 5.565 1H4.44Z'
                fill='#4AAD7C'
              />
            </svg>
          </div>
        ),
        value: (
          <>
            1 EGLD ={' '}
            <Hint
              {...defaultHintProps}
              content={
                <span className='text-[8px] md:text-[10px] leading-tight'>
                  {exchangeRate}
                </span>
              }
              className='-mt-1 sm:-mt-0.5'
            >
              {new DefiUtils(exchangeRate).toFixed(
                sm ? 6 : 3,
                DefiUtils.ROUND_DOWN,
              )}
            </Hint>{' '}
            {token}
          </>
        ),
      },
      {
        caption: t('service-fee'),
        captionTooltip: t('tp-10'),
        tooltipMax: md ? 312 : 318,
        value: `≈ ${totalFee}%`,
      },
    ];
  }, [
    annualEarnings,
    defaultHintProps,
    exchangeRate,
    exchangeRateTooltip,
    receivedValue.formatted,
    receivedValue.value,
    sm,
    supplyHsEGLd,
    t,
    token,
    totalFee,
  ]);

  const totalAvailable = useMemo(() => {
    return new DefiUtils(subtractGasFee(nativeUserBalance.underlyingBalance))
      .toFullDecimals(nativeMarket.underlying.decimals)
      .toString();
  }, [nativeUserBalance.underlyingBalance, nativeMarket.underlying.decimals]);

  const walletBalance = useMemo(() => {
    return new DefiUtils(nativeUserBalance.underlyingBalance)
      .toFullDecimals(nativeMarket.underlying.decimals)
      .toString();
  }, [nativeUserBalance.underlyingBalance, nativeMarket.underlying.decimals]);

  const walletBalanceUSD = useMemo(() => {
    return new DefiUtils(walletBalance)
      .toUSD(nativeMarket.underlying.priceUSD)
      .toString();
  }, [nativeMarket.underlying.priceUSD, walletBalance]);

  const decimalsLimit = useMemo(
    () => nativeMarket.underlying.decimals,
    [nativeMarket.underlying.decimals],
  );

  const isBtnDisabled = useMemo(() => {
    return (
      !hasEnoughEGLDBalance ||
      new DefiUtils(inputValue).isGreaterThan(totalAvailable) ||
      new DefiUtils(inputValue).isLessThan(minValue)
    );
  }, [hasEnoughEGLDBalance, inputValue, totalAvailable, minValue]);

  const handleChange = (value: string) => {
    if (!value) {
      onChange(value);
      return;
    }

    if (new DefiUtils(value).isGreaterThan(MAX_AMOUNT)) {
      return;
    }

    onChange(value);
  };

  const error = useLiquidStakingMessageError({
    operation: OPERATION_KEYS.STAKE,
    inputValue: inputValue || '0',
    balance: walletBalance,
    minValue: minValue,
    maxValue: totalAvailable,
  });

  const handleMax = () => {
    const value = new DefiUtils(
      new DefiUtils(totalAvailable).isGreaterThan(MAX_AMOUNT)
        ? MAX_AMOUNT
        : totalAvailable,
    ).removeScientificNotation();

    onChange(value);
  };

  const handleStake = async () => {
    if (
      hasPendingTransactions ||
      !isAllowedTransaction(ALLOWED_TRANSACTIONS.LIQUID_APP_STAKE)
    ) {
      return;
    }

    switch (token) {
      case EXCHANGE_RATE_KEY.sEGLD: {
        const values = hasSplitLiquidStakingStake
          ? divideValuePerMax(inputValue, SPLIT_AMOUNT)
          : [inputValue];

        const txs = values.map((amount) => delegate({ amount }));

        const result = new DefiUtils(inputValue)
          .multipliedBy(egldToSEgld)
          .toString();

        await signTransactions(txs, {
          group: TRANSACTION_GROUP_TYPE.LIQUID,
          subgroup: TRANSACTION_SUBGROUP_TYPE.STAKE_UNDERLYING,
          result,
        });
        break;
      }

      case EXCHANGE_RATE_KEY.HsEGLD: {
        const values = hasSplitLiquidStakingStake
          ? divideValuePerMax(inputValue, SPLIT_AMOUNT)
          : [inputValue];

        const txs = values.map((amount) =>
          lendegate({
            amount: new DefiUtils(amount)
              .toBasicUnits(sEgldMarket.underlying.decimals)
              .toString(),
            hasEnterMarket: supplyHsEGLd,
          }),
        );

        const result = new DefiUtils(inputValue)
          .multipliedBy(egldToHsEgld)
          .toString();

        const marketInteractedAvaiableToRemove = marketsInteracted.find(
          ({ canRemove }) => canRemove,
        );

        const hasMaxMarketPerAccountByController =
          marketsInteractedAmount >= +controller.maxMarketsPerAccount &&
          !marketsInteracted
            .map(({ address }) => address)
            .includes(markets?.SEGLD?.address) &&
          (marketInteractedAvaiableToRemove?.address.length || 0) > 0;

        await signTransactions(
          [
            ...(hasMaxMarketPerAccountByController && supplyHsEGLd
              ? [
                  removeAccountMarket(
                    marketInteractedAvaiableToRemove?.address || '',
                  ),
                ]
              : []),
            ...txs,
            ...(supplyHsEGLd && hasHtmStaked ? [rebalancePortfolio()] : []),
          ],
          {
            group: TRANSACTION_GROUP_TYPE.LIQUID,
            subgroup: supplyHsEGLd
              ? TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN_COLLATERAL
              : TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN,
            result,
            isSecuencial: (supplyHsEGLd && hasMaxMarketPerAccountByController) || (supplyHsEGLd && hasHtmStaked),
          },
        );
        break;
      }
    }
  };

  const handleConnectWallet = () => {
    dispatch(
      openPopup({
        name: 'connect_bridge_multiverse_wallet',
      }),
    );
  };

  const sendRebalance = useMemo(() => {
    return (
      isLoggedIn &&
      supplyHsEGLd &&
      hasHtmStaked &&
      token === EXCHANGE_RATE_KEY.HsEGLD
    );
  }, [isLoggedIn, supplyHsEGLd, hasHtmStaked, token]);

  useEffect(() => {
    if (supplyHsEGLd && token === EXCHANGE_RATE_KEY.HsEGLD)
      setColorBooster(false);
    else setColorBooster(true);
  }, [setColorBooster, supplyHsEGLd, token]);
  return (
    <>
      <ChooseToken
        checked={token}
        onChange={(t: any) => changeToken(t)}
        className='mb-9'
      />

      <div className=''>
        <Label
          label={t('amount')}
          valueText={t('wallet-balance')}
          token='EGLD'
          value={walletBalance}
          valueUSD={walletBalanceUSD}
          tooltip={
            <div className='text-[8px] max-w-[233px] md:max-w-[292px] md:text-[10px] leading-tight'>
              <Trans
                i18nKey='tp-17'
                components={[<ColoredName token={token} />]}
                ns='liquid-app'
                tOptions={{ token: 'EGLD' }}
              />
            </div>
          }
          className='mb-1'
        />

        <AmountInput
          estimation={estimation}
          onChange={handleChange}
          inputValue={inputValue}
          onMax={handleMax}
          decimalsLimit={decimalsLimit}
        />
      </div>

      {error && (
        <AmountLimitErrors
          {...error}
          operation={operation}
          token='EGLD'
          onChange={handleChange}
        />
      )}

      {notes.length && (
        <Notes
          className='mb-9 mt-9'
          notes={notes.filter((e: any) => !e?.hidden?.())}
        />
      )}

      {token === EXCHANGE_RATE_KEY.sEGLD ? (
        <AcceptDeal
          className='mb-9'
          iconSrc='https://cdn.app.hatom.com/images/liquidstaking/app/pig.png'
          asMessage
          message={
            <Trans
              i18nKey='stake-note-segld'
              components={[
                <span className='text-black dark:text-white' />,
                <a
                  href={ASHSWAP_FORM}
                  target='_blank'
                  className='text-[#006FFF] hover:underline'
                  rel='noreferrer'
                />,
              ]}
              ns='liquid-app'
            />
          }
          checked={true}
        />
      ) : (
        <AcceptDeal
          className='mb-9'
          iconSrc='https://cdn.app.hatom.com/images/liquidstaking/app/battery.png'
          message={
            <div>
              <Trans
                i18nKey='stake-note'
                tOptions={{
                  a: `${formatNumber(new DefiUtils(rewardsTokensAPY).plus(boosterRewardsAPY).toString())}%`,
                  b: `${formatNumber(
                    percentages
                      .reduce(
                        (prev: any, { value }: { value: number }) =>
                          prev.plus(value),
                        new DefiUtils(0),
                      )
                      .toString(),
                    2,
                  )}%`,
                  token: 'HsEGLD',
                }}
                components={[
                  <span
                    className={classNames({
                      'text-[#7E19FF]':
                        token === EXCHANGE_RATE_KEY.HsEGLD && supplyHsEGLd,
                    })}
                  />,
                  <span
                    className={classNames({
                      'text-[#45A373]':
                        token === EXCHANGE_RATE_KEY.HsEGLD && supplyHsEGLd,
                    })}
                  />,
                ]}
                ns='liquid-app'
              />
            </div>
          }
          checked={token === EXCHANGE_RATE_KEY.HsEGLD ? supplyHsEGLd : false}
          disabled={token === EXCHANGE_RATE_KEY.sEGLD}
          onChange={check}
        />
      )}

      {isLoggedIn ? (
        <Hint
          content={
            hasPendingTransactions
              ? t('translation:wait-tx-inprogress')
              : !isWhiteListed()
              ? t('translation:tx-not-enable-to-sign')
              : t('translation:tx-not-allowed')
          }
          placement='top-center'
          className='w-full'
          unvisible={
            !(
              hasPendingTransactions ||
              !isAllowedTransaction(ALLOWED_TRANSACTIONS.LIQUID_APP_STAKE) ||
              !isWhiteListed()
            )
          }
        >
          <SubmitButton onClick={handleStake} disabled={isBtnDisabled}>
            {t('stake')}
          </SubmitButton>
        </Hint>
      ) : (
        <SubmitButton secondary onClick={handleConnectWallet}>
          {t('translation:connect-wallet')}
        </SubmitButton>
      )}

      {sendRebalance && (
        <div className='GreenAlert mt-3 flex min-h-[34px] w-full items-center justify-center rounded-[6px] bg-[#BDF9DF] translate-y-1'>
          <div className='flex max-w-[320px] items-start gap-0.5 text-[12px] leading-[15px] text-[#3E9179]'>
            <img
              src='https://cdn.app.hatom.com/images/green-info.svg'
              alt='green-info'
              className='-translate-x-[1px] translate-y-[1.5px]'
            />
            <div className='flex w-full flex-col'>
              <span className='translate-x-[0.3px] text-center'>
                You'll need to sign two transactions to rebalance your
              </span>
              <span className='-translate-x-[6px] text-center'>
                position within the HTM Booster.
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
