import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';
import DefiUtils from 'defi-utils';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import {
  calcNeedRebalance,
  calcTotalCollateralUSD,
  formatAccountRewardsWithoutIndexer,
  formatAcumulators,
  formatBoosterAccount,
  getAcumulatorsPairsIds,
} from '@/store/parsers/booster-parser';
import { getRewardsPrices } from '@/store/parsers/reward-batch-parser';
import { Market } from '@/store/protocol';
import { addAction } from '@/store/queue';

import * as airdropService from '@/services/airdrop';
import blockchainService from '@/services/blockchain';
import {
  AccountMarketBoosterData,
  BoosterRewardsBatch,
} from '@/services/blockchain/lens/types';
import * as xexchangeService from '@/services/xexchange';
import logger from '@/utils/logger';
import { calculatePercentageBoost } from '@/utils/math/booster';
import { MAX_CACHE_TIME, queryClient } from '@/utils/query';

export interface TTotalCollateralTokens {
  [key: string]: string;
}

export interface BoosterAccount {
  speed: string;
  amount: string;
  reward: {
    price: string;
    decimals: number;
    token: string;
    symbol: string;
  };
  marketBooster: {
    priceIntegral: string;
    priceIntegralTimestamp: number;
  };
  accountMarketBooster: {
    staked: string;
    priceIntegral: string;
    priceIntegralTimestamp: number;
  };
  totalBoosterApy: string;
  accountBoosterApy: string;
  accountBoosterAPYWithPenalty: string;
  maxAccountBoosterApy: string;
  hasCollateral: boolean;
  percentageBoost: string;
}

export interface AccountBoosterReward {
  tokenId: string;
  userRewardBalance: string;
  userRewardBalanceUSD: string;
  symbol: string;
  priceUSD: string;
  logo: string;
  name: string;
  decimals: number;
}

export const fakeBoosterAccount: Record<string, BoosterAccount> = {
  WTAO: {
    speed: '1',
    amount: '0',
    reward: {
      price: '1',
      decimals: 6,
      token: 'USDC',
      symbol: 'USDC',
    },
    marketBooster: {
      priceIntegral: '0',
      priceIntegralTimestamp: new Date().getTime(),
    },
    accountMarketBooster: {
      staked: '0',
      priceIntegral: '0',
      priceIntegralTimestamp: new Date().getTime(),
    },
    totalBoosterApy: '93600',
    accountBoosterApy: '93600',
    accountBoosterAPYWithPenalty: '93600',
    maxAccountBoosterApy: '93600',
    hasCollateral: false,
    percentageBoost: '0',
  },
  SWTAO: {
    speed: '1',
    amount: '0',
    reward: {
      price: '1',
      decimals: 6,
      token: 'USDC',
      symbol: 'USDC',
    },
    marketBooster: {
      priceIntegral: '0',
      priceIntegralTimestamp: new Date().getTime(),
    },
    accountMarketBooster: {
      staked: '0',
      priceIntegral: '0',
      priceIntegralTimestamp: new Date().getTime(),
    },
    totalBoosterApy: '50400',
    accountBoosterApy: '50400',
    accountBoosterAPYWithPenalty: '50400',
    maxAccountBoosterApy: '50400',
    hasCollateral: false,
    percentageBoost: '0',
  },
};

export interface BoosterBatchState {
  boosterAccount: Record<string, BoosterAccount>;
  totalBoosterCollateralMoneyMarkets: TTotalCollateralTokens;
  totalStaked: {
    totalStaked: string;
    totalStakedUsd: string;
  };
  percentageBoost: string;
  claims: {
    id: string;
    claimTimestamp: string;
    claimed: boolean;
    amount: string;
  }[];
  hasStaked: boolean;
  maximumApyBooster: string;
  boosterBoosters: {
    [key: string]: {
      exchangeRate: string;
      premium: string;
    };
  };
  slippage: string;
  needRebalance: boolean;
  accountSnapshots: AccountSnapshot[];
  accountRewards: AccountBoosterReward[];
  accountBoosterMarkets: string[];
}

interface AccountSnapshot {
  snapshot: {
    date: string;
    id: string;
    totalCollateralUSD: string;
    totalBoostCollateralUSD: string;
  };
  totalCollateralUSD: string;
  totalBoostCollateralUSD: string;
  totalStakedUSD: string;
  percentageBoost: string;
  hasUshReward: boolean;
  collateralPercentage: string;
  poolShare: string;
}

const initialState: BoosterBatchState = {
  boosterAccount: {},
  totalBoosterCollateralMoneyMarkets: {} as TTotalCollateralTokens,
  totalStaked: {
    totalStaked: '0',
    totalStakedUsd: '0',
  },
  percentageBoost: '0',
  claims: {} as BoosterBatchState['claims'],
  hasStaked: false,
  maximumApyBooster: '0.1',
  boosterBoosters: {},
  slippage: '0',
  needRebalance: false,
  accountSnapshots: [],
  accountRewards: [],
  accountBoosterMarkets: [],
};

export const boosterSlice = createSlice({
  name: 'booster',
  initialState,
  reducers: {
    setBoosterBatch: (
      state,
      action: PayloadAction<Partial<BoosterBatchState>>,
    ) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof BoosterBatchState] = value;
      });
    },
  },
});

export const { setBoosterBatch } = boosterSlice.actions;

export const getBoosterBatch =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const { address: accountAddress } = state.auth.account;
      const { booster, markets, userBalances } = state.protocol;

      if (!booster.address) {
        return;
      }

      const htmMarket = markets?.HTM;

      const maximumApyBooster = new DefiUtils(booster.stakingRatioThreshold)
        .toFullDecimals(18)
        .toSafeString();

      const [
        boosterAccountStake,
        accountBoosterRewards,
        accountMarketsBoosterData,
        boosterRewardsBatches,
        marketsBoosterData,
        boosterAccountClaims,
        accountBoosterMarkets,
      ] = await Promise.all([
        blockchainService.boosterBatch.getAccountStake(
          booster.address,
          accountAddress,
        ),
        blockchainService.lens.getAccountBoosterRewards(accountAddress),
        blockchainService.lens.getAccountMarketsBoosterData(accountAddress),
        blockchainService.lens.getBoosterRewardsBatches(),
        blockchainService.lens.getMarketsBoosterData(),
        blockchainService.lens.getBoosterAccountClaims(accountAddress),
        blockchainService.lens.getAccountBoosterMarkets(accountAddress),
      ]);

      const rewardsIds = [
        ...new Set(boosterRewardsBatches.map(({ tokenId }) => tokenId)),
      ];

      const [rewardsPrices] = await Promise.all([
        getRewardsPrices(markets, rewardsIds),
      ]);

      const marketsMap = Object.values(markets).reduce(
        (prev, current) => ({
          ...prev,
          [current.address]: current,
        }),
        {} as Record<string, Market>,
      );

      const rewardsMap = Object.values(markets).reduce(
        (prev, current) => ({
          ...prev,
          [current.underlying.id]: current.underlying,
        }),
        {} as Record<string, Market['underlying']>,
      );

      const accountMarketsBoosterDataMap = accountMarketsBoosterData.reduce(
        (prev, current) => ({
          ...prev,
          [current.market]: current,
        }),
        {} as Record<string, AccountMarketBoosterData>,
      );
      const boosterRewardsBatchesMap = boosterRewardsBatches.reduce(
        (prev, current) => ({
          ...prev,
          [current.moneyMarket]: current,
        }),
        {} as Record<string, BoosterRewardsBatch>,
      );

      const boosterAccount = formatBoosterAccount({
        marketsBoosterData,
        marketsMap,
        rewardsMap,
        boosterRewardsBatchesMap,
        accountMarketsBoosterDataMap,
        rewardsPrices,
        htmMarket,
        userBalances,
        maximumApyBooster,
      });

      const accountRewards = formatAccountRewardsWithoutIndexer({
        accountBoosterRewards,
        rewardsMap,
        rewardsPrices,
      });

      const totalStaked = boosterAccountStake.toString();

      const totalStakedValues = {
        totalStaked: new DefiUtils(totalStaked || '0')
          .toFullDecimals(htmMarket?.underlying?.decimals)
          .toString(),
        totalStakedUsd: new DefiUtils(totalStaked || '0')
          .toFullDecimals(htmMarket?.underlying?.decimals)
          .multipliedBy(htmMarket?.underlying?.priceUSD)
          .toString(),
      };

      const totalBoosterCollateralMoneyMarkets = marketsBoosterData.reduce(
        (prev, { market, storedTotalCollateralTokens }) => {
          const marketItem = marketsMap[market];

          return {
            ...prev,
            [marketItem?.underlying.symbol]: storedTotalCollateralTokens,
          };
        },
        {} as Record<string, string>,
      );

      const percentageBoost = new DefiUtils(
        calculatePercentageBoost({
          boosterAccount,
          htmPrice: htmMarket?.underlying?.priceUSD,
          totalStaked,
          markets,
          userBalances,
          maximumApyBooster,
        }),
      ).toString();

      const hasStaked = new DefiUtils(
        totalStakedValues.totalStaked,
      ).isGreaterThan(0);

      const claims = boosterAccountClaims
        .filter(({ claimed }) => !claimed)
        .map((claimItem) => ({
          ...claimItem,
          id: `${accountAddress}-${claimItem.id}`,
        }));

      await dispatch(
        addAction(
          setBoosterBatch({
            claims,
            accountRewards,
            boosterAccount: {
              ...boosterAccount,
            },
            totalStaked: totalStakedValues,
            totalBoosterCollateralMoneyMarkets,
            percentageBoost,
            hasStaked,
            maximumApyBooster,
            needRebalance: calcNeedRebalance({
              maximumApyBooster,
              totalCollateralUSD: calcTotalCollateralUSD({
                markets,
                userBalances,
              }),
              totalStakedUsd: totalStakedValues.totalStakedUsd,
              boosterAccount,
              userBalances,
              htmMarket,
              markets,
            }),
            accountBoosterMarkets,
          }),
        ),
      );
    } catch (error) {
      logger.error('store:getBoosterBatch', error);
      captureException(error);
    }
  };

export const getAccountSnapshotsPromise =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const {
        account: { address: accountAddress },
      } = state.auth;

      if (accountAddress.length === 0) {
        dispatch(
          setBoosterBatch({
            accountSnapshots: [],
          }),
        );
        return;
      }

      if (state.boosterBatch.accountSnapshots.length !== 0) {
        return;
      }

      const responseSnapshots = await queryClient.fetchQuery({
        queryKey: ['snapshots'],
        queryFn: async () => {
          return airdropService.getAllSnapshots();
        },
        cacheTime: MAX_CACHE_TIME,
        staleTime: MAX_CACHE_TIME,
      });

      const responseAccountSnapshot = await queryClient.fetchQuery({
        queryKey: ['accountSnapshots', accountAddress],
        queryFn: async () => {
          return airdropService.getAccountByAddress(accountAddress);
        },
        cacheTime: MAX_CACHE_TIME,
        staleTime: MAX_CACHE_TIME,
      });

      const accountSnapshotMap = responseAccountSnapshot.reduce(
        (prev, current) => {
          return {
            ...prev,
            [current.snapshotId]: current,
            [current.date]: current,
          };
        },
        {} as Record<string, airdropService.AccountSnapshotByAddress>,
      );

      const accountSnapshots = responseSnapshots
        .map((snapshotItem) => {
          const accountSnapshotItem =
            accountSnapshotMap[snapshotItem.snapshotId] ||
            accountSnapshotMap[snapshotItem.date] ||
            {};

          const {
            hasUshReward = false,
            totalCollateralUSD = '0',
            totalBoostCollateralUSD = '0',
            totalStakedUSD = '0',
            percentageBoost = '0',
          } = accountSnapshotItem;

          const { airdropTotalBoostCollateralUSD } = snapshotItem;

          return {
            snapshot: {
              date: new Date(parseInt(snapshotItem.timestamp)).toISOString(),
              id: snapshotItem.snapshotId,
              totalCollateralUSD: snapshotItem.airdropTotalCollateralUSD,
              totalBoostCollateralUSD: airdropTotalBoostCollateralUSD,
            },
            totalCollateralUSD,
            totalBoostCollateralUSD,
            totalStakedUSD,
            percentageBoost,
            hasUshReward,
            collateralPercentage: new DefiUtils(totalStakedUSD)
              .dividedBy(totalBoostCollateralUSD)
              .multipliedBy(100)
              .toSafeString(),
            poolShare: hasUshReward
              ? new DefiUtils(totalBoostCollateralUSD)
                  .dividedBy(airdropTotalBoostCollateralUSD)
                  .multipliedBy(100)
                  .toSafeString()
              : '0',
          };
        })
        .sort(
          (a, b) =>
            new Date(b.snapshot.date).getTime() -
            new Date(a.snapshot.date).getTime(),
        );

      dispatch(
        setBoosterBatch({
          accountSnapshots,
        }),
      );
    } catch (error) {
      logger.error('store:getAccountSnapshots', error);
      captureException(error);
    }
  };

/**
 * WARNING:this function does not wait for the result (save time)
 */
export const getAccountSnapshots = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(getAccountSnapshotsPromise());
  } catch (error) {
    logger.error('store:getBoosterBatch', error);
    captureException(error);
  }
};

/**
 * WARNING:this function does not wait for the result (save time)
 */
const getBoosterBoostersAsync =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const {
        protocol: { markets },
      } = getState();
      const boosterAccumulators =
        await blockchainService.lens.getBoosterAccumulators();

      const tokensMap = Object.values(markets).reduce(
        (prev, current) => ({
          ...prev,
          [current.underlying.id]: current.underlying,
        }),
        {} as Record<
          string,
          {
            id: string;
            name: string;
            decimals: number;
            symbol: string;
          }
        >,
      );

      const formatBoosterAccumulators = boosterAccumulators.map(
        ({ premium, tokenId, swapPath }) => {
          return {
            premium,
            rewardsToken: {
              id: tokenId,
              symbol: tokensMap[tokenId]?.symbol || '',
            },
            swapPath: swapPath.map(({ outputTokenId }) => ({
              id: outputTokenId,
            })),
          };
        },
      );

      const pairsIds = getAcumulatorsPairsIds(formatBoosterAccumulators);

      // @ts-ignore
      const uniquePairsIds = [...new Set(pairsIds)];

      const pairs = await xexchangeService.getPairs(uniquePairsIds);

      const boosterBoosters = formatAcumulators({
        pairs,
        acumulators: formatBoosterAccumulators,
      });

      await dispatch(
        setBoosterBatch({
          boosterBoosters,
        }),
      );
    } catch (error) {
      logger.error('store:getBoosterBatch', error);
      captureException(error);
    }
  };

export const getBoosterBoosters =
  () => async (dispatch: AppDispatch) => {
    dispatch(getBoosterBoostersAsync());
  };

export const boosterSelector = (state: RootState) => state.boosterBatch;

export default boosterSlice.reducer;
