import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import {
  formatAccountRewardsWithoutIndexer,
  formatAcumulators,
  formatRewardsTokenWithoutIndexer,
  getAcumulatorsPairsIds,
  getBalanceByIdentifiers,
  getRewardsPrices,
} from '@/store/parsers/reward-batch-parser';
import { MARKET_KEY } from '@/store/protocol';
import { addAction } from '@/store/queue';

import blockchainService from '@/services/blockchain';
import * as xexchangeService from '@/services/xexchange';
import logger from '@/utils/logger';

export const REWARDS_DECIMALS: Record<string, number> = {
  HTM: 18,
  WEGLD: 18,
};

export interface RewardBatch {
  batchId: string;
  tokenId: string;
  type: 'Borrow' | 'Supply';
  speed: string;
  symbol: string;
  priceUSD: string;
  logo: string;
  name: string;
  decimals: string;
  apy: string;
  userApy: string;
}

export interface AccountControllerReward {
  tokenId: string;
  userRewardBalance: string;
  userRewardBalanceUSD: string;
  symbol: string;
  priceUSD: string;
  logo: string;
  name: string;
  decimals: number;
}

export interface Market {
  totalRewardsBorrowAPY: string;
  totalRewardsSupplyAPY: string;
  userRewardsBorrowAPY: string;
  userRewardsSupplyAPY: string;
  rewards: RewardBatch[];
}

export interface RewardBatchState {
  markets: Record<MARKET_KEY, Market>;
  accountRewards: AccountControllerReward[];
  controllerBoosters: {
    [key: string]: {
      exchangeRate: string;
      premium: string;
    };
  };
}

export const fakeMarketsRewards: Record<string, Market> = {};

const initialState: RewardBatchState = {
  markets: {} as Record<MARKET_KEY, Market>,
  accountRewards: [],
  controllerBoosters: {},
};

export const rewardBatchSlice = createSlice({
  name: 'rewardBatch',
  initialState,
  reducers: {
    setRewardBatch: (
      state,
      action: PayloadAction<Partial<RewardBatchState>>,
    ) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof RewardBatchState] = value;
      });
    },
  },
});

export const { setRewardBatch } = rewardBatchSlice.actions;

export const getRewardsBatch =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const {
        controller: { address: controllerAddress },
        markets: protocolMarkets,
        userBalances,
      } = state.protocol;
      const { address: accountAddress } = state.auth.account;

      const controllerRewardsBatches =
        await blockchainService.lens.getControllerRewardsBatches();

      const [accountControllerRewards, pricesMap, totalCollateralTokensMap] =
        await Promise.all([
          blockchainService.lens.getAccountControllerRewards(accountAddress),
          getRewardsPrices(protocolMarkets, [
            // @ts-ignore
            ...new Set(
              controllerRewardsBatches.map(
                (rewardsToken) => rewardsToken.tokenId,
              ),
            ),
          ]),
          getBalanceByIdentifiers(controllerAddress, [
            // @ts-ignore
            ...new Set(
              Object.values(protocolMarkets).map(({ hToken }) => hToken.id),
            ),
          ]),
        ]);

      const formattedRewardsMarkets = formatRewardsTokenWithoutIndexer({
        controllerRewardsBatches,
        pricesMap,
        protocolMarkets,
        totalCollateralTokensMap,
        userBalances,
      });

      const formattedAccountRewards = formatAccountRewardsWithoutIndexer({
        protocolMarkets,
        accountControllerRewards,
        pricesMap,
      });

      await dispatch(
        addAction(
          setRewardBatch({
            markets: {
              ...formattedRewardsMarkets,
            },
            accountRewards: formattedAccountRewards,
          }),
        ),
      );
    } catch (error) {
      logger.error('store:getRewardsBatch', error);
      captureException(error);
    }
  };

/**
 * WARNING:this function does not wait for the result (save time)
 */
const getControllerBoosterAsync =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const {
        protocol: { markets },
      } = getState();
      const controllerAccumulators =
        await blockchainService.lens.getControllerAccumulators();

      const tokensMap = Object.values(markets).reduce(
        (prev, current) => ({
          ...prev,
          [current.underlying.id]: current.underlying,
        }),
        {} as Record<
          string,
          {
            id: string;
            name: string;
            decimals: number;
            symbol: string;
          }
        >,
      );

      const formatControllerAccumulators = controllerAccumulators.map(
        ({ premium, tokenId, swapPath }) => {
          return {
            premium,
            rewardsToken: {
              id: tokenId,
              symbol: tokensMap[tokenId]?.symbol || '',
            },
            swapPath: swapPath.map(({ outputTokenId }) => ({
              id: outputTokenId,
            })),
          };
        },
      );

      const pairsIds = getAcumulatorsPairsIds(formatControllerAccumulators);

      // @ts-ignore
      const uniquePairsIds = [...new Set(pairsIds)];

      const pairs = await xexchangeService.getPairs(uniquePairsIds);

      const controllerBoosters = formatAcumulators({
        pairs,
        acumulators: formatControllerAccumulators,
      });

      await dispatch(
        setRewardBatch({
          controllerBoosters,
        }),
      );
    } catch (error) {
      logger.error('store:getControllerBatch', error);
      captureException(error);
    }
  };

export const getControllerBoosters = () => async (dispatch: AppDispatch) => {
  dispatch(getControllerBoosterAsync());
};

export const rewardBatchSelector = (state: RootState) => state.rewardBatch;

export default rewardBatchSlice.reducer;
