import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import useDebounce from '@/hooks/useDebounce';

import {
  accountSelector,
  updateAccountTxs,
  updateESDTTokenBalance,
} from '@/store/auth';
import {
  getAccountSnapshots,
  getBoosterBatch,
  getBoosterBoosters,
} from '@/store/booster';
import { getProposals } from '@/store/governance';
import { useAppDispatch, useAppSelector } from '@/store/index';
import { indexerSelector, setIndexer } from '@/store/indexer';
import { getLandingInfo } from '@/store/landing';
import { getLendAppMarkets } from '@/store/lend-app';
import { getLiquidLockingAppInfo } from '@/store/liquid-locking-app';
import {
  getLiquidStakingAppInfo,
  getValidators,
} from '@/store/liquid-staking-app';
import { getLiquidStakingTaoAppInfo } from '@/store/liquid-staking-tao-app';
import { getHistoryPoints, getMarkets } from '@/store/market';
import { getPrices } from '@/store/price';
import { updateProtocol } from '@/store/protocol';
import { execQueue } from '@/store/queue';
import { getControllerBoosters, getRewardsBatch } from '@/store/reward-batch';
import {
  hasPendingTransactionsSelector,
  transactionSelector,
} from '@/store/transaction';

import logger from '@/utils/logger';
import { calcFunctionTime } from '@/utils/time';

import { ROUTES } from '@/types/enums';

const MARKET_REFRESH_TIME_SM: number = 10 * 1000; // in milliseconds
const MARKET_REFRESH_TIME_LG: number = 1 * 60 * 1000; // in milliseconds

// const MARKET_REFRESH_TIME_SM: number = 10 * 1000 * 60; // in milliseconds
// const MARKET_REFRESH_TIME_LG: number = 1 * 60 * 1000 * 60; // in milliseconds

const useCompletedTransactionKey = () => {
  const { currentTransactions } = useAppSelector(transactionSelector);

  const completedTransactionStatuses = useMemo(
    () =>
      currentTransactions
        .filter(({ status }) => status !== 'pending')
        .map(({ status }) => status)
        .join('-'),
    [currentTransactions],
  );

  const completedTransactionKey = useMemo(
    () => `${completedTransactionStatuses}`,
    [completedTransactionStatuses],
  );

  return completedTransactionKey;
};

const useLoaders = () => {
  const dispatch = useAppDispatch();
  const { hasProtocolInfo, hasFirstInfo, hasFirstAccountInfo } =
    useAppSelector(indexerSelector);

  const { address: accountAddress } = useAppSelector(accountSelector);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const router = useRouter();

  const [lastRoute, setLastRoute] = useState(router.route);

  const completedTransactionKey = useCompletedTransactionKey();

  // refresh the info
  useEffect(() => {
    setRefreshCounter((state) => state + 1);
  }, [accountAddress, completedTransactionKey, router.route, hasProtocolInfo]);

  // refresh the info every x time
  useEffect(() => {
    if (hasPendingTransactions) {
      return;
    }

    const refreshInferval = accountAddress
      ? MARKET_REFRESH_TIME_SM
      : MARKET_REFRESH_TIME_LG;

    const interval = setInterval(() => {
      setRefreshCounter((state) => state + 1);
    }, refreshInferval);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter, hasPendingTransactions]);

  const refreshCounterDelay = useMemo(() => {
    if (!hasFirstInfo) {
      return 0;
    }

    return 1_000;
  }, [hasFirstInfo]);

  const refreshCounterDebounce = useDebounce(
    refreshCounter,
    refreshCounterDelay,
  );

  const isLoadingFirstInfo = useMemo(() => {
    if (lastRoute !== router.route) {
      return true;
    }

    if (
      !['/'].includes(router.route) &&
      accountAddress &&
      !hasFirstAccountInfo
    ) {
      return true;
    }

    if (!hasFirstInfo) {
      return true;
    }

    return false;
  }, [
    lastRoute,
    router.route,
    accountAddress,
    hasFirstAccountInfo,
    hasFirstInfo,
  ]);

  useEffect(() => {
    dispatch(setIndexer({ isLoadingFirstInfo }));
  }, [isLoadingFirstInfo]);

  return {
    refreshCounter,
    setRefreshCounter,
    refreshCounterDebounce,
    setLastRoute,
  };
};

const useStoreIntervalProtocolInfo = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const { address: accountAddress } = useAppSelector(accountSelector);
  const { hasProtocolInfo, isLoadingInfo, hasFirstInfo, hasFirstAccountInfo } =
    useAppSelector(indexerSelector);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const { currentTransactions } = useAppSelector(transactionSelector);
  const [lastCurrentTransactionId, setLastCurrentTransactionId] =
    useState<string>('');

  const currentTransactionId = useMemo(() => {
    return currentTransactions.map(({ hash }) => hash).join('_');
  }, [currentTransactions]);

  const { setRefreshCounter, refreshCounterDebounce, setLastRoute } =
    useLoaders();

  const promisesByRoute = () => {
    switch (router.route) {
      case ROUTES.GOVERNANCE: {
        return [
          // dispatch(getVoteNfts()),
          dispatch(getProposals()),
          // dispatch(getUserBalances()),
        ];
      }

      case ROUTES.PRICES: {
        return [
          calcFunctionTime(
            `2. a. promisesByRoute${router.route}/getPrices`,
            () => dispatch(getPrices()),
          ),
        ];
      }

      case ROUTES.MARKETS: {
        return [
          calcFunctionTime(
            `2. a. promisesByRoute${router.route}/getRewardsBatch`,
            () => dispatch(getRewardsBatch()),
          ),
          calcFunctionTime(
            `2. b. promisesByRoute${router.route}/getMarkets`,
            () => dispatch(getMarkets()),
          ),
          calcFunctionTime(
            `2. c. promisesByRoute${router.route}/getHistoryPoints`,
            () => dispatch(getHistoryPoints()),
          ),
          calcFunctionTime(
            `2. d. promisesByRoute${router.route}/getBoosterBatch`,
            () => dispatch(getBoosterBatch()),
          ),
        ];
      }

      case ROUTES.HOME: {
        return [
          calcFunctionTime(
            `2. a. promisesByRoute${router.route}/getLandingInfo`,
            () => dispatch(getLandingInfo()),
          ),
        ];
      }

      case ROUTES.LIQUID: {
        return [
          calcFunctionTime(
            `2. a. promisesByRoute${router.route}/getRewardsBatch`,
            () => dispatch(getRewardsBatch()),
          ),
          calcFunctionTime(
            `2. b. promisesByRoute${router.route}/getBoosterBatch`,
            () => dispatch(getBoosterBatch()),
          ),
          calcFunctionTime(
            `2. c. promisesByRoute${router.route}/getAccountSnapshots`,
            () => dispatch(getAccountSnapshots()),
          ),
          calcFunctionTime(
            `2. d. promisesByRoute${router.route}/getBoosterBoosters+async`,
            () => dispatch(getBoosterBoosters()),
          ),
          calcFunctionTime(
            `2. e. promisesByRoute${router.route}/getControllerBoosters+async`,
            () => dispatch(getControllerBoosters()),
          ),
        ];
      }

      case ROUTES.LIQUID_MULTIVERSX_APP: {
        return [
          calcFunctionTime(
            `2. a. promisesByRoute${router.route}/getRewardsBatch`,
            () => dispatch(getRewardsBatch()),
          ),
          calcFunctionTime(
            `2. b. promisesByRoute${router.route}/getLiquidStakingAppInfo`,
            () => dispatch(getLiquidStakingAppInfo()),
          ),
          calcFunctionTime(
            `2. c. promisesByRoute${router.route}/getLiquidLockingAppInfo`,
            () => dispatch(getLiquidLockingAppInfo()),
          ),
          calcFunctionTime(
            `2. d. promisesByRoute${router.route}/getValidators`,
            () => dispatch(getValidators()),
          ),
          calcFunctionTime(
            `2. e. promisesByRoute${router.route}/getBoosterBatch`,
            () => dispatch(getBoosterBatch()),
          ),
          calcFunctionTime(
            `2. f. promisesByRoute${router.route}/getAccountSnapshots`,
            () => dispatch(getAccountSnapshots()),
          ),
          calcFunctionTime(
            `2. g. promisesByRoute${router.route}/getBoosterBoosters+async`,
            () => dispatch(getBoosterBoosters()),
          ),
          calcFunctionTime(
            `2. h. promisesByRoute${router.route}/getControllerBoosters+async`,
            () => dispatch(getControllerBoosters()),
          ),
        ];
      }

      case ROUTES.LIQUID_TAO_APP: {
        return [
          calcFunctionTime(
            `2. a. promisesByRoute${router.route}/getRewardsBatch`,
            () => dispatch(getRewardsBatch()),
          ),
          calcFunctionTime(
            `2. b. promisesByRoute${router.route}/getLiquidStakingTaoAppInfo`,
            () => dispatch(getLiquidStakingTaoAppInfo()),
          ),
          calcFunctionTime(
            `2. c. promisesByRoute${router.route}/getLiquidLockingAppInfo`,
            () => dispatch(getLiquidLockingAppInfo()),
          ),
          calcFunctionTime(
            `2. d. promisesByRoute${router.route}/getBoosterBatch`,
            () => dispatch(getBoosterBatch()),
          ),
          calcFunctionTime(
            `2. e. promisesByRoute${router.route}/getAccountSnapshots`,
            () => dispatch(getAccountSnapshots()),
          ),
          calcFunctionTime(
            `2. f. promisesByRoute${router.route}/getBoosterBoosters+async`,
            () => dispatch(getBoosterBoosters()),
          ),
          calcFunctionTime(
            `2. g. promisesByRoute${router.route}/getControllerBoosters+async`,
            () => dispatch(getControllerBoosters()),
          ),
        ];
      }

      case ROUTES.LEND: {
        return [
          calcFunctionTime(
            `2. a. promisesByRoute${router.route}/getBoosterBatch`,
            () => dispatch(getBoosterBatch()),
          ),
          calcFunctionTime(
            `2. b. promisesByRoute${router.route}/getAccountSnapshots`,
            () => dispatch(getAccountSnapshots()),
          ),
          calcFunctionTime(
            `2. c. promisesByRoute${router.route}/getBoosterBoosters+async`,
            () => dispatch(getBoosterBoosters()),
          ),
          calcFunctionTime(
            `2. d. promisesByRoute${router.route}/getControllerBoosters+async`,
            () => dispatch(getControllerBoosters()),
          ),
          calcFunctionTime(
            `2. e. promisesByRoute${router.route}/getRewardsBatch`,
            () => dispatch(getRewardsBatch()),
          ),
          calcFunctionTime(
            `2. f. promisesByRoute${router.route}/getLendAppMarkets`,
            () => dispatch(getLendAppMarkets()),
          ),
          calcFunctionTime(
            `2. g. promisesByRoute${router.route}/getLiquidLockingAppInfo`,
            () => dispatch(getLiquidLockingAppInfo()),
          ),
        ];
      }

      default: {
        return [
          calcFunctionTime(
            `2. a. promisesByRoute${router.route}/getRewardsBatch`,
            () => dispatch(getRewardsBatch()),
          ),
          calcFunctionTime(
            `2. b. promisesByRoute${router.route}/getBoosterBatch`,
            () => dispatch(getBoosterBatch()),
          ),
          calcFunctionTime(
            `2. c. promisesByRoute${router.route}/getAccountSnapshots`,
            () => dispatch(getAccountSnapshots()),
          ),
          calcFunctionTime(
            `2. d. promisesByRoute${router.route}/getBoosterBoosters+async`,
            () => dispatch(getBoosterBoosters()),
          ),
          calcFunctionTime(
            `2. e. promisesByRoute${router.route}/getControllerBoosters+async`,
            () => dispatch(getControllerBoosters()),
          ),
        ];
      }
    }
  };

  const getProtocolInfo = async () => {
    await dispatch(setIndexer({ isLoadingInfo: true }));

    await calcFunctionTime(`1.  before:promisesByRoute${router.route}`, () =>
      Promise.all([
        calcFunctionTime('1. a. before:promisesByRoute:updateProtocol', () =>
          dispatch(updateProtocol()),
        ),
        ['/'].includes(router.route)
          ? null
          : calcFunctionTime(
              '1. b.   before:promisesByRoute:updateESDTTokenBalance',
              () => dispatch(updateESDTTokenBalance()),
            ),
        ,
        ...(!hasFirstAccountInfo ||
        currentTransactionId !== lastCurrentTransactionId
          ? [
              calcFunctionTime(
                '1. c. before:promisesByRoute:updateAccountTxs',
                () => dispatch(updateAccountTxs()),
              ),
            ]
          : []),
      ]),
    );

    await calcFunctionTime(`2.  promisesByRoute${router.route}`, () =>
      Promise.all(promisesByRoute()),
    );

    setRefreshCounter((state) =>
      state === refreshCounterDebounce ? state : state + 1,
    );

    setLastRoute(router.route);
    setLastCurrentTransactionId(currentTransactionId);

    await Promise.all([
      dispatch(execQueue()),
      dispatch(
        setIndexer({
          isLoadingInfo: false,
          hasFirstInfo: true,
          hasFirstAccountInfo: accountAddress ? true : false,
        }),
      ),
    ]);

    window.totalCount =
      (window.totalCount || 0) + window.blockchainCount + window.gatewayCount;

    const infoData = {
      ...window.time,
      gatewayCounters: {
        blockchainCount: window.blockchainCount,
        gatewayCount: window.gatewayCount,
        totalCount: window.totalCount,
      },
    };

    logger.info(infoData);
    window.time = {};
    window.blockchainCount = 0;
    window.gatewayCount = 0;
  };

  // get all the data
  useEffect(() => {
    if (
      !hasProtocolInfo ||
      isLoadingInfo ||
      (hasPendingTransactions && hasFirstInfo && hasFirstAccountInfo)
    ) {
      return;
    }

    getProtocolInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounterDebounce]);
};

export default useStoreIntervalProtocolInfo;
