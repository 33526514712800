import { ExtensionProvider } from '@multiversx/sdk-extension-provider/out';
import { captureException } from '@sentry/nextjs';

import { useAppDispatch, useAppSelector } from '@/store';
import { clearAuthStates } from '@/store/auth';
import {
  clearDappProvider,
  networkStateSelector,
  setNetworkState,
} from '@/store/network';

import { webviewProvider } from '@/providers/webviewProvider';

const useExperimentalWebviewLogin = () => {
  const provider = useAppSelector(
    networkStateSelector<ExtensionProvider>('dappProvider'),
  );

  const dispatch = useAppDispatch();

  const initiateLogin = async () => {
    try {
      dispatch(setNetworkState('dappProvider', webviewProvider));
    } catch (e) {
      captureException(e);
    }
  };

  const logout = async () => {
    await provider.logout();
    dispatch(clearAuthStates());
    dispatch(clearDappProvider());
  };

  return {
    initiateLogin,
    logout,
  };
};

export default useExperimentalWebviewLogin;
