import client from '@/services/tao-stats/client';
import logger from '@/utils/logger';

export const getTaoApr = async (): Promise<string> => {
  const response = await client.get(`/tao-stats/tao-validator-apr`);

  logger.info({
    taoAPR: response.data,
  });

  return response.data;
};
