import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { useLogin } from '@/hooks/auth/useLogin';
import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';
import useLendInteraction, {
  MAX_BORROW_LIMIT_MARGIN,
  MAX_RECOMMENDED_BORROW_LIMIT_MARGIN,
} from '@/hooks/interaction/useLendInteraction';
import useMediaQuery from '@/hooks/useMediaQuery';
import useToggle from '@/hooks/useToggle';

import CurrencyInputButton from '@/components/CurrencyInputButton';
import Hint from '@/components/Hint';
import ApproEqualIcon from '@/components/Icons/ApproEqualIcon';
import FutureStatsRightArrow from '@/components/Icons/FutureStatsRightArrow';
import InfoIcon from '@/components/Icons/InfoIcon';
import LabeledImage from '@/components/LabeledImage';
import { NetAPYTooltipContent } from '@/components/Lending/NetAPYTooltipContent';
import { SEGLDLiquidStakingApyPercentage } from '@/components/Lending/SEGLDLiquidStakingApyPercentage';
import PopupBed from '@/components/popups/PopupBed';
import PopupHead from '@/components/popups/PopupBed/components/PopupHead';
import CRUDButton from '@/components/popups/SupplyPopup/components/CRUDButton';
import OperationFormBottomAlerts from '@/components/popups/SupplyPopup/OperationFormBottomAlerts';
import Tabber from '@/components/Tabber';
import TransactionButton from '@/components/TransactionButton';

import { useAppSelector } from '@/store';
import { boosterSelector } from '@/store/booster';
import { lendAppSelector } from '@/store/lend-app';
import { popupSelector } from '@/store/popup';
import {
  ASSET_ALT_NAME,
  calcHasMaxMarketPerAccount,
  H_TOKEN_DECIMALS,
  MARKET_KEY,
  nativeMarketSelector,
  nativeUserBalanceSelector,
  protocolSelector,
  TOKEN_LOGO_MAP,
} from '@/store/protocol';
import { rewardBatchSelector } from '@/store/reward-batch';
import {
  hasPendingTransactionsSelector,
  TRANSACTION_GROUP_TYPE,
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';

import {
  formatNumber,
  getBorrowLimitUsedPercent,
  getTokenBorrowLimit,
  nFormatter,
  subtractGasFee,
  toFormattedDecimalNum,
  wadBasicUnit,
} from '@/utils/helpers';
import logger from '@/utils/logger';
import {
  getTotalSupplyAPYByMarket,
  getTotalSupplyAPYByMarketLiquidity,
} from '@/utils/math/apy';

enum COLLATERAL_MODE {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

enum TABS {
  SUPPLY = 'supply',
  WITHDRAW = 'withdraw',
  COLLATERAL = 'collateral',
}

const SUPPLY_TABS = [TABS.SUPPLY, TABS.WITHDRAW, TABS.COLLATERAL];

const getTabIndex = (id: TABS) => {
  return SUPPLY_TABS.indexOf(id as TABS);
};

const SupplyPopup = () => {
  const router = useRouter();
  const {
    liquidStaking: { apy: liquidStakingAPY },
    liquidStakingTao: { apy: liquidStakingTaoAPY },
    userBalances,
    controller,
    marketsInteracted,
    marketsInteractedAmount,
  } = useAppSelector(protocolSelector);
  const { boosterAccount, hasStaked: hasHtmStaked } =
    useAppSelector(boosterSelector);

  const { markets } = useAppSelector(lendAppSelector);
  const {
    showLiquidStakingAPY,
    showLiquidStakingTaoAPY,
    account: {
      borrowBalanceUSD,
      borrowLimitUSD: borrowLimitUSDWithoutSimulate,
      // borrowLimitUSDWithoutSimulate,
    },
  } = useAppSelector(lendAppSelector);
  const { markets: rewardMarkets } = useAppSelector(rewardBatchSelector);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);

  const {
    data: { assetKey, collateralTab },
  } = useAppSelector(popupSelector);
  const hasMaxMarketPerAccount = useMemo(
    () =>
      calcHasMaxMarketPerAccount({
        userBalances,
        controller,
        token: assetKey,
      }),
    [assetKey, controller, userBalances],
  );
  const { t } = useTranslation();
  const { isLoggedIn } = useLogin();
  const { signTransactions, isAllowedTransaction, isWhiteListed } =
    useSignMultipleTransactions();

  const {
    supplyAPY,
    hTokenExchangeRate,
    totalSupply,
    collateralFactor,
    accountBalances,
    cash: marketReserves,
    totalBorrow,
    logo,
    name,
    underlying: { decimals, priceUSD },
    hToken: { symbol: hTokenCurrency },
    assetKey: currency,
    supplyCap,
    mintEnabled,
    address,
  } = markets[assetKey as MARKET_KEY];

  const rewardsSupplyToken = useMemo(() => {
    const rewards = rewardMarkets[assetKey as MARKET_KEY]?.rewards || [];

    return rewards
      .filter(({ type }) => type === 'Supply')
      .filter(({ speed }) => new DefiUtils(speed).isGreaterThan('0'));
  }, [assetKey, rewardMarkets]);

  const rewardsTokensAPY = useMemo(() => {
    return rewardMarkets[assetKey as MARKET_KEY]?.totalRewardsSupplyAPY || '0';
  }, [assetKey, rewardMarkets]);

  const nativeUserBalance = useAppSelector(nativeUserBalanceSelector);
  const nativeMarket = useAppSelector(nativeMarketSelector);
  const { underlyingBalance: accountBalanceEGLD } = nativeUserBalance;

  const [selectedTab, setSelectedTab] = useState<TABS>(
    collateralTab ? TABS.COLLATERAL : TABS.SUPPLY,
  );
  const [maxSelected, setMaxSelected] = useState<boolean>(false);
  const [collateralOption, setCollateralOption] = useState<string>(
    COLLATERAL_MODE.ADD,
  );
  const isSupplying = selectedTab === TABS.SUPPLY;
  const isCollateralying = selectedTab === TABS.COLLATERAL;
  const isWithdrawing = selectedTab === TABS.WITHDRAW;
  const isAddingCollateral =
    isCollateralying && collateralOption === COLLATERAL_MODE.ADD;
  const isRemovingCollateral =
    isCollateralying && collateralOption === COLLATERAL_MODE.REMOVE;

  const [inputValue, setInputValue] = useState<string>('0');
  const [inputValueAsBigNumber, setInputValueAsBigNumber] =
    useState<string>('0');
  const [inputValueAsHTokenBigNumber, setInputValueAsHTokenBigNumber] =
    useState<string>('0');

  const {
    supplyLiquidity,
    removeCollateral,
    withdrawLiquidity,
    addCollateral,
    rebalancePortfolio,
    removeAccountMarket,
  } = useLendInteraction();

  const isRemainingValue = (value: string) =>
    new DefiUtils(value).isLessThanOrEqualTo('0.999999999999999999') &&
    !new DefiUtils(value).isEqualTo('0');

  /** For EGLD, it considers the cost of the gas when the user wants to spend
   * the maximum available amount in the
   */

  const maxAccountBalanceAsBigNumber = useMemo(
    () =>
      assetKey === nativeMarket.underlying.symbol
        ? subtractGasFee(accountBalances.underlyingWallet)
        : accountBalances.underlyingWallet,
    [
      accountBalances.underlyingWallet,
      assetKey,
      nativeMarket.underlying.symbol,
    ],
  );

  const enoughEGLDBalance = useMemo(
    () => new DefiUtils(subtractGasFee(accountBalanceEGLD)).isGreaterThan(0),
    [accountBalanceEGLD],
  );

  /** Decimal notation of account balance of underlying tokens */
  const maxAccountBalanceDecimals = useMemo(
    () =>
      new DefiUtils(accountBalances.underlyingWallet)
        .toFullDecimals(decimals)
        .toString(),
    [accountBalances.underlyingWallet, decimals],
  );

  const maxCash = useMemo<string>(() => {
    const value = new DefiUtils(marketReserves).minus(
      new DefiUtils(totalBorrow).multipliedBy('0.0001'),
    );
    return value.isLessThanOrEqualTo(0)
      ? '0'
      : value.toFixed(0, DefiUtils.ROUND_FLOOR);
  }, [marketReserves, totalBorrow]);

  /** Native action (Tx) is executed in Htoken but expressed in underlying */
  const maxWithdrawAmountFullDecimals = useMemo(
    () =>
      new DefiUtils(accountBalances.underlyingHTokenWallet)
        .toFullDecimals(decimals)
        .toString(),
    [accountBalances.underlyingHTokenWallet, decimals],
  );

  const collateralUnderlyingBalanceFullDecimals = useMemo(
    () =>
      new DefiUtils(accountBalances.underlyingCollateral)
        .toFullDecimals(decimals)
        .toString(),
    [accountBalances.underlyingCollateral, decimals],
  );

  const hasTakenBorrows = useMemo(
    () =>
      Object.entries(markets)
        .map(([, { accountBalances }]) => accountBalances.borrow)
        .reduce((prev, current) => prev.plus(current), new DefiUtils('0'))
        .isGreaterThan('0'),
    [markets],
  );

  const withdrawMaxValueSimulateAsBigNumber = useMemo(
    () =>
      new DefiUtils(maxWithdrawAmountFullDecimals)
        // .multipliedBy(supplySimulate)
        .multipliedBy(`1e${decimals}`)
        .toString(),
    [decimals, maxWithdrawAmountFullDecimals],
  );

  const maxRemoveCollateralUnderlyingAmount = useMemo(() => {
    const maxBorrowLimitUSD = new DefiUtils(
      borrowLimitUSDWithoutSimulate || 0,
    ).minus(
      new DefiUtils(borrowBalanceUSD).dividedBy(
        hasTakenBorrows ? MAX_RECOMMENDED_BORROW_LIMIT_MARGIN : 1,
      ),
    );

    const safeMaxBorrowLimitUSD = maxBorrowLimitUSD.isGreaterThan(0)
      ? maxBorrowLimitUSD
      : 0;

    const collateralFactorFullDecimals = new DefiUtils(
      collateralFactor,
    ).dividedBy(wadBasicUnit);

    const maxCollateralBorrowLimitUSD = new DefiUtils(
      safeMaxBorrowLimitUSD,
    ).dividedBy(collateralFactorFullDecimals);

    return new DefiUtils(maxCollateralBorrowLimitUSD)
      .fromUSD(priceUSD)
      .toString();
  }, [
    borrowBalanceUSD,
    borrowLimitUSDWithoutSimulate,
    collateralFactor,
    hasTakenBorrows,
    priceUSD,
  ]);

  const recommendedRemovableCollateralBalance = useMemo(
    () =>
      new DefiUtils(maxRemoveCollateralUnderlyingAmount).isLessThanOrEqualTo(
        new DefiUtils(collateralUnderlyingBalanceFullDecimals),
      )
        ? maxRemoveCollateralUnderlyingAmount
        : collateralUnderlyingBalanceFullDecimals,
    [
      collateralUnderlyingBalanceFullDecimals,
      maxRemoveCollateralUnderlyingAmount,
    ],
  );

  const removableCollateralBalanceLimitUSD = useMemo(
    () =>
      new DefiUtils(borrowLimitUSDWithoutSimulate || 0).minus(
        new DefiUtils(borrowBalanceUSD || 0),
      ),
    [borrowBalanceUSD, borrowLimitUSDWithoutSimulate],
  );

  const removableCollateralEquivalentLimit = useMemo(
    () =>
      new DefiUtils(
        removableCollateralBalanceLimitUSD.isGreaterThan(0)
          ? removableCollateralBalanceLimitUSD
          : 0,
      )
        .dividedBy(new DefiUtils(collateralFactor).dividedBy(wadBasicUnit))
        .multipliedBy(MAX_BORROW_LIMIT_MARGIN),
    [collateralFactor, removableCollateralBalanceLimitUSD],
  );

  const removableCollateralTokenLimit = useMemo(
    () =>
      new DefiUtils(removableCollateralEquivalentLimit)
        .fromUSD(priceUSD)
        .toNumber(),
    [priceUSD, removableCollateralEquivalentLimit],
  );

  const maxRemovableCollateral = useMemo(
    () =>
      new DefiUtils(removableCollateralTokenLimit).isGreaterThanOrEqualTo(
        collateralUnderlyingBalanceFullDecimals,
      )
        ? collateralUnderlyingBalanceFullDecimals
        : removableCollateralTokenLimit,
    [collateralUnderlyingBalanceFullDecimals, removableCollateralTokenLimit],
  );

  /** Max amounts of input expressed in underlying token, used for input
   * validations and to set the max amount value (for each use case)
   * when MAX button is selected */

  const collateralMaxValueAsBigNumber = useMemo(
    () =>
      isAddingCollateral
        ? new DefiUtils(maxWithdrawAmountFullDecimals)
            .multipliedBy(`1e${decimals}`)
            .toString()
        : new DefiUtils(recommendedRemovableCollateralBalance)
            .multipliedBy(`1e${decimals}`)
            .toString(),
    [
      decimals,
      isAddingCollateral,
      maxWithdrawAmountFullDecimals,
      recommendedRemovableCollateralBalance,
    ],
  );

  const collateralLimit = useMemo(
    () =>
      isAddingCollateral
        ? maxWithdrawAmountFullDecimals
        : maxRemovableCollateral,
    [isAddingCollateral, maxRemovableCollateral, maxWithdrawAmountFullDecimals],
  );

  const hasEnoughMarketReserves = useMemo(
    () =>
      new DefiUtils(maxCash).isGreaterThanOrEqualTo(
        new DefiUtils(inputValueAsBigNumber || 0),
      ),
    [inputValueAsBigNumber, maxCash],
  );

  const isCashGreatherThanUnderlyingTokenSupplyBalance = useMemo(
    () =>
      new DefiUtils(maxCash).isGreaterThan(
        new DefiUtils(accountBalances.underlyingHTokenWallet || 0),
      ),
    [accountBalances.underlyingHTokenWallet, maxCash],
  );

  const withdrawMaxValueAsBigNumber = useMemo(
    () =>
      isCashGreatherThanUnderlyingTokenSupplyBalance
        ? accountBalances.underlyingHTokenWallet
        : maxCash,
    [
      accountBalances.underlyingHTokenWallet,
      isCashGreatherThanUnderlyingTokenSupplyBalance,
      maxCash,
    ],
  );

  const collateralLimitAsBigNumber = useMemo(
    () =>
      new DefiUtils(collateralLimit).multipliedBy(`1e${decimals}`).toString(),
    [collateralLimit, decimals],
  );

  const maxRequestAmounts = useMemo(() => {
    return {
      [TABS.SUPPLY]: {
        setMaxValueAsBigNumber: maxAccountBalanceAsBigNumber,
        limitAsBigNumber: maxAccountBalanceAsBigNumber,
      },
      [TABS.WITHDRAW]: {
        setMaxValueAsBigNumber: withdrawMaxValueAsBigNumber,
        limitAsBigNumber: withdrawMaxValueAsBigNumber,
      },
      [TABS.COLLATERAL]: {
        setMaxValueAsBigNumber: collateralMaxValueAsBigNumber,
        limitAsBigNumber: collateralLimitAsBigNumber,
      },
    };
  }, [
    collateralLimitAsBigNumber,
    collateralMaxValueAsBigNumber,
    maxAccountBalanceAsBigNumber,
    withdrawMaxValueAsBigNumber,
  ]);

  const footerNotes = useMemo(() => {
    return {
      [TABS.SUPPLY]: [
        {
          label: t('wallet-balance'),
          value: maxAccountBalanceDecimals,
        },
      ],
      [TABS.WITHDRAW]: [
        {
          label: t('currently-supplying'),
          value: maxWithdrawAmountFullDecimals,
        },
      ],
      [TABS.COLLATERAL]: [
        {
          label: t('supplied-liquidity'),
          value: maxWithdrawAmountFullDecimals,
        },
        {
          label: t('current-collateral'),
          value: collateralUnderlyingBalanceFullDecimals,
        },
      ],
    };
  }, [
    maxAccountBalanceDecimals,
    collateralUnderlyingBalanceFullDecimals,
    t,
    maxWithdrawAmountFullDecimals,
  ]);

  const isValidInput = useMemo(
    () =>
      inputValueAsBigNumber &&
      new DefiUtils(inputValueAsBigNumber).isGreaterThan('0')
        ? true
        : false,
    [inputValueAsBigNumber],
  );

  const hasMinHTokenValue = useMemo(
    () =>
      inputValueAsBigNumber &&
      new DefiUtils(inputValueAsHTokenBigNumber).isGreaterThanOrEqualTo(
        isSupplying ? 2 : 1.5,
      )
        ? true
        : false,
    [inputValueAsBigNumber, inputValueAsHTokenBigNumber, isSupplying],
  );

  const hasFunds = useMemo(
    () =>
      new DefiUtils(
        maxRequestAmounts[selectedTab].limitAsBigNumber,
      ).isGreaterThan('0'),
    [maxRequestAmounts, selectedTab],
  );

  const isValidAmountRequest = useMemo(() => {
    if (!isValidInput) {
      return false;
    }

    if (maxSelected) {
      return true;
    }

    return new DefiUtils(inputValueAsBigNumber).isLessThanOrEqualTo(
      maxRequestAmounts[selectedTab].limitAsBigNumber,
    );
  }, [
    inputValueAsBigNumber,
    isValidInput,
    maxRequestAmounts,
    maxSelected,
    selectedTab,
  ]);

  // user put the same number as max button
  const maxSelectedByInput = useMemo(
    () =>
      new DefiUtils(
        maxRequestAmounts[selectedTab].setMaxValueAsBigNumber,
      ).isEqualTo(inputValueAsBigNumber),
    [inputValueAsBigNumber, maxRequestAmounts, selectedTab],
  );

  const isAddAllCollateral = useMemo(
    () => maxSelected || maxSelectedByInput,
    [maxSelected, maxSelectedByInput],
  );

  /** Input value (in underlying) converted to USD */
  const inputPriceUSD = useMemo(
    () =>
      isValidAmountRequest
        ? new DefiUtils(inputValue).toUSD(priceUSD).toNumber()
        : 0,
    [inputValue, isValidAmountRequest, priceUSD],
  );

  const inputPriceHToken = useMemo(
    () =>
      isValidAmountRequest
        ? new DefiUtils(inputValue)
            .toBasicUnits(decimals)
            .toTokens(hTokenExchangeRate)
        : '0',
    [decimals, hTokenExchangeRate, inputValue, isValidAmountRequest],
  );

  /** Used only to display HToken conversion */
  const inputPriceHTokenFullDecimals = useMemo(
    () =>
      new DefiUtils(inputPriceHToken)
        .toFullDecimals(H_TOKEN_DECIMALS)
        .toNumber(),
    [inputPriceHToken],
  );

  /**Determines how the input value should be used in the estimation calculation
  for each use case */
  const getEstimationFactor = (): string => {
    if (isCollateralying) return isAddingCollateral ? '1' : '-1';
    return '0';
  };

  /** The real borrow limit that results froml the collateral input,
   * considering the collateralFactor
   */
  const collateralInputBorrowLimit = getTokenBorrowLimit(
    inputPriceUSD.toString(),
    collateralFactor,
  );

  /** Borrow Limits estimation box */
  const nextBorrowLimitAmountUSD =
    isValidAmountRequest &&
    new DefiUtils(borrowLimitUSDWithoutSimulate)
      .plus(
        new DefiUtils(collateralInputBorrowLimit as string).multipliedBy(
          getEstimationFactor(),
        ),
      )
      .toNumber();

  /** Next Borrow Limit Estimation based in inputValue (expressed in underlying) */
  const nextBorrowLimitUsedPercentage =
    isValidAmountRequest &&
    nextBorrowLimitAmountUSD &&
    getBorrowLimitUsedPercent(borrowBalanceUSD, nextBorrowLimitAmountUSD);

  const isRemovingAllCollateral = useMemo(() => {
    if (
      isRemovingCollateral &&
      (maxSelected || maxSelectedByInput) &&
      !hasTakenBorrows
    ) {
      return true;
    }

    const s_inputValueAsHTokenBigNumber = new DefiUtils(
      inputValueAsHTokenBigNumber,
    )
      .toFixed(0, DefiUtils.ROUND_HALF_UP)
      .toString();

    return new DefiUtils(s_inputValueAsHTokenBigNumber).isGreaterThanOrEqualTo(
      accountBalances.collateral,
    );
  }, [
    isRemovingCollateral,
    maxSelected,
    maxSelectedByInput,
    hasTakenBorrows,
    inputValueAsHTokenBigNumber,
    accountBalances.collateral,
  ]);

  const onTabToggle = (tabNumber: number) => {
    setSelectedTab(SUPPLY_TABS[tabNumber]);
  };

  const isMaxSupplyCap = useMemo(() => {
    if (!isSupplying || supplyCap === 'Infinity') {
      return false;
    }

    const totalSupplyUnderlying = new DefiUtils(totalSupply)
      .toUnderlying(hTokenExchangeRate)
      .toFullDecimals(decimals)
      .toString();

    const futureSupplyCap = new DefiUtils(totalSupplyUnderlying)
      .plus(inputValue)
      .toString();

    return new DefiUtils(futureSupplyCap).isGreaterThanOrEqualTo(supplyCap);
  }, [
    decimals,
    hTokenExchangeRate,
    inputValue,
    isSupplying,
    supplyCap,
    totalSupply,
  ]);

  const getActionButtonLabel = (): string => {
    if (isSupplying) {
      if (!isLoggedIn || !hasFunds) return t('no-funds-available');
      if (!isValidInput && hasFunds) return t('supply');
      if (!mintEnabled) return t('mint-not-enabled', 'Supply not enabled');
      if (!hasMinHTokenValue) return t('the-amount-is-too-low');
      if (isValidInput) {
        if (isMaxSupplyCap) {
          return t('amount-exceeds-supply-cap');
        }

        return isValidAmountRequest ? t('supply') : t('no-funds-available');
      }
      return t('supply');
    }

    if (isWithdrawing) {
      const disabled =
        hasEnoughMarketReserves || isRemainingValue(inputValueAsBigNumber)
          ? t('no-funds-to-withdraw')
          : t('insufficient-market-liquidity');
      if (!isLoggedIn || !hasFunds) return t('no-funds-to-withdraw');
      if (!isValidInput && hasFunds) return t('withdraw');
      if (isRemainingValue(inputValueAsBigNumber))
        return t('no-funds-to-withdraw');
      if (!hasEnoughMarketReserves) return t('insufficient-market-liquidity');
      if (!hasMinHTokenValue) return t('the-amount-is-too-low');
      if (isValidInput) {
        return isValidAmountRequest ? t('withdraw') : disabled;
      }
      return t('withdraw');
    }

    if (isAddingCollateral) {
      if (!isLoggedIn || !hasFunds) return t('no-funds-to-add-collateral');
      if (!isValidInput && hasFunds) return t('add-collateral');
      if (!hasMinHTokenValue) return t('the-amount-is-too-low');
      if (isValidInput) {
        if (isAddingCollateral && hasMaxMarketPerAccount) {
          return t('limit-money-market-reached');
        }

        return isValidAmountRequest
          ? t('add-collateral')
          : t('no-funds-to-add-collateral');
      }
      return t('add-collateral');
    }

    if (isRemovingCollateral) {
      if (!isLoggedIn || !hasFunds) return t('no-collateral-available');
      if (!isValidInput && hasFunds) return t('remove-collateral');
      if (!hasMinHTokenValue) return t('the-amount-is-too-low');
      if (isValidInput) {
        return isValidAmountRequest
          ? t('remove-collateral')
          : t('no-collateral-available');
      }
      return t('remove-collateral');
    }

    return t('unknown');
  };

  const getActionButtonDisabled = (): boolean => {
    return (
      (isSupplying && !mintEnabled) ||
      !isLoggedIn ||
      !isValidInput ||
      (!maxSelected && !isValidAmountRequest) ||
      !hasFunds ||
      !enoughEGLDBalance ||
      !hasMinHTokenValue ||
      isMaxSupplyCap ||
      (isWithdrawing && !hasEnoughMarketReserves) ||
      (isWithdrawing && isRemainingValue(inputValueAsBigNumber)) ||
      (isAddingCollateral && hasMaxMarketPerAccount)
    );
  };

  const [actionButton, setActionButton] = useState({
    disabled: true,
    label: getActionButtonLabel(),
  });

  const handleSetMaxAmount = () => {
    setMaxSelected(!maxSelected);
  };

  const handleInputValueChange = (value: any) => {
    setInputValue(value);
    setInputValueAsHTokenBigNumber(
      new DefiUtils(value)
        .toBasicUnits(decimals)
        .toTokens(hTokenExchangeRate)
        .toString(),
    );
    setInputValueAsBigNumber(
      new DefiUtils(value).toBasicUnits(decimals).toString(),
    );
  };

  useEffect(() => {
    setMaxSelected(false);
    // eslint-disable-next-line
  }, [selectedTab, isAddingCollateral]);

  useEffect(() => {
    if (!maxSelected) {
      setInputValue('0');
      setInputValueAsHTokenBigNumber('0');
      setInputValueAsBigNumber('0');
      return;
    }

    const newInputValue = new DefiUtils(
      maxRequestAmounts[selectedTab].setMaxValueAsBigNumber,
    )
      .toFullDecimals(decimals)
      .toFixed(decimals, DefiUtils.ROUND_DOWN);

    const decimalsPlaces = new DefiUtils(newInputValue).decimalPlaces() || 0;

    const formattedInputValue = new DefiUtils(newInputValue).toFixed(
      decimalsPlaces,
      DefiUtils.ROUND_DOWN,
    );

    const inputValueAsHToken = new DefiUtils(
      maxRequestAmounts[selectedTab].setMaxValueAsBigNumber,
    )
      .toTokens(hTokenExchangeRate)
      .toString();

    if (
      new DefiUtils(
        maxRequestAmounts[selectedTab].setMaxValueAsBigNumber,
      ).isLessThanOrEqualTo(0)
    ) {
      setInputValue('');
      setInputValueAsHTokenBigNumber('');
      setInputValueAsBigNumber('');
    } else {
      setInputValue(formattedInputValue);
      setInputValueAsHTokenBigNumber(inputValueAsHToken);
      setInputValueAsBigNumber(
        maxRequestAmounts[selectedTab].setMaxValueAsBigNumber,
      );
    }
    // eslint-disable-next-line
  }, [maxSelected]);

  useEffect(() => {
    setActionButton({
      disabled: getActionButtonDisabled(),
      label: getActionButtonLabel(),
    });
    // eslint-disable-next-line
  }, [selectedTab, collateralOption, inputValueAsBigNumber]);

  const queryHasRebalance = useMemo(() => {
    return (
      typeof router.query.hasRebalance === 'undefined' ||
      router.query.hasRebalance === 'true'
    );
  }, [router.query.hasRebalance]);

  const isWithdrawingAll = useMemo(() => {
    return isWithdrawing && (maxSelected || maxSelectedByInput);
  }, [isWithdrawing, maxSelected, maxSelectedByInput]);

  const sendRebalance = useMemo(() => {
    const boosterByMarket = Object.entries(boosterAccount)
      .map((a) => {
        return {
          market: a[0],
          ...a[1],
        };
      })
      .filter(
        (booster) =>
          booster.speed &&
          !new DefiUtils(
            userBalances[booster.market as MARKET_KEY].collateralBalance,
          ).isZero(),
      )
      .map((b) => b.market);

    let marketsWithCollateral = 0;
    boosterByMarket.forEach((m) => {
      if (
        new DefiUtils(
          userBalances[m as MARKET_KEY].collateralBalance,
        ).isGreaterThan(0)
      )
        marketsWithCollateral++;
    });

    const isSameMarket =
      marketsWithCollateral === 1 && boosterByMarket.includes(assetKey);

    return (
      selectedTab === TABS.COLLATERAL &&
      hasHtmStaked &&
      !isSameMarket &&
      queryHasRebalance
    );
  }, [
    boosterAccount,
    assetKey,
    selectedTab,
    hasHtmStaked,
    queryHasRebalance,
    userBalances,
  ]);

  const handleButtonClick = async () => {
    if (hasPendingTransactions) {
      return;
    }

    const s_inputValueAsBigNumber = new DefiUtils(
      inputValueAsBigNumber,
    ).toFixed(0, DefiUtils.ROUND_DOWN);

    const s_inputValueAsHTokenBigNumber = new DefiUtils(
      inputValueAsHTokenBigNumber,
    ).toFixed(0, DefiUtils.ROUND_HALF_UP);

    switch (selectedTab) {
      case TABS.SUPPLY: {
        await signTransactions(
          [
            supplyLiquidity({
              tokenKey: assetKey,
              amountAsBigInteger: s_inputValueAsBigNumber,
            }),
          ],
          {
            token: assetKey,
            group: TRANSACTION_GROUP_TYPE.LENDING,
            subgroup: TRANSACTION_SUBGROUP_TYPE.SUPPLY,
            result: inputValue,
          },
        );

        return;
      }

      case TABS.WITHDRAW: {
        if (
          isWithdrawingAll &&
          !isCashGreatherThanUnderlyingTokenSupplyBalance
        ) {
          await signTransactions(
            [
              withdrawLiquidity({
                tokenKey: assetKey,
                amountAsBigNumber: maxCash,
                isUnderlyingAmount: true,
              }),
            ],
            {
              token: assetKey,
              group: TRANSACTION_GROUP_TYPE.LENDING,
              subgroup: TRANSACTION_SUBGROUP_TYPE.WITHDRAW,
              result: inputValue,
            },
          );

          return;
        }

        if (
          isWithdrawingAll &&
          isCashGreatherThanUnderlyingTokenSupplyBalance
        ) {
          await signTransactions(
            [
              withdrawLiquidity({
                tokenKey: assetKey,
                amountAsBigNumber: accountBalances.hTokenWallet,
                isUnderlyingAmount: false,
              }),
            ],
            {
              token: assetKey,
              group: TRANSACTION_GROUP_TYPE.LENDING,
              subgroup: TRANSACTION_SUBGROUP_TYPE.WITHDRAW,
              result: inputValue,
            },
          );

          return;
        }

        if (!isWithdrawingAll) {
          const isInputValueAsHTokenBigNumberGreatherThanHTokenAccountBalance =
            new DefiUtils(s_inputValueAsHTokenBigNumber).isGreaterThan(
              accountBalances.hTokenWallet,
            );

          const amountAsBigNumber =
            isInputValueAsHTokenBigNumberGreatherThanHTokenAccountBalance
              ? accountBalances.hTokenWallet
              : s_inputValueAsHTokenBigNumber;

          await signTransactions(
            [
              withdrawLiquidity({
                tokenKey: assetKey,
                amountAsBigNumber,
                isUnderlyingAmount: false,
              }),
            ],
            {
              token: assetKey,
              group: TRANSACTION_GROUP_TYPE.LENDING,
              subgroup: TRANSACTION_SUBGROUP_TYPE.WITHDRAW,
              result: inputValue,
            },
          );

          return;
        }

        return;
      }

      case TABS.COLLATERAL: {
        if (isAddingCollateral) {
          const marketInteractedAvaiableToRemove = marketsInteracted.find(
            ({ canRemove }) => canRemove,
          );

          const hasMaxMarketPerAccountByController =
            marketsInteractedAmount >= +controller.maxMarketsPerAccount &&
            !marketsInteracted
              .map(({ address }) => address)
              .includes(address) &&
            (marketInteractedAvaiableToRemove?.address.length || 0) > 0;

          await signTransactions(
            [
              ...(hasMaxMarketPerAccountByController
                ? [
                    removeAccountMarket(
                      marketInteractedAvaiableToRemove?.address || '',
                    ),
                  ]
                : []),
              addCollateral({
                tokenKey: assetKey,
                amountAsBigNumber: s_inputValueAsBigNumber,
                isMax: isAddAllCollateral,
                maxHTokenBalance: accountBalances.hTokenWallet,
              }),
              ...(sendRebalance ? [rebalancePortfolio()] : []),
            ],
            {
              token: assetKey,
              group: TRANSACTION_GROUP_TYPE.LENDING,
              subgroup: TRANSACTION_SUBGROUP_TYPE.ADD_COLLATERAL,
              result: inputValue,
              isSecuencial: sendRebalance || hasMaxMarketPerAccount,
            },
          );

          return;
        }

        if (isRemovingCollateral) {
          const amountAsHTokenBigNumber = isRemovingAllCollateral
            ? accountBalances.collateral
            : s_inputValueAsHTokenBigNumber;

          logger.info({
            amountAsHTokenBigNumber,
            isRemovingAllCollateral,
            'accountBalances.collateral': accountBalances.collateral,
            inputValueAsHTokenBigNumber,
            s_inputValueAsHTokenBigNumber: new DefiUtils(
              inputValueAsHTokenBigNumber,
            ).toFixed(0, DefiUtils.ROUND_HALF_CEIL),
          });

          await signTransactions(
            [
              removeCollateral({
                tokenKey: assetKey,
                amountAsHTokenBigNumber,
                isMax: isRemovingAllCollateral,
              }),
              ...(hasHtmStaked && queryHasRebalance
                ? [rebalancePortfolio()]
                : []),
            ],
            {
              token: assetKey,
              group: TRANSACTION_GROUP_TYPE.LENDING,
              subgroup: TRANSACTION_SUBGROUP_TYPE.REMOVE_COLLATERAL,
              result: inputValue,
              isSecuencial: hasHtmStaked && queryHasRebalance,
            },
          );

          return;
        }

        return;
      }
    }
  };

  const prefix = useMemo<string>(() => {
    const hasRemainingSymbol =
      (isCollateralying || isWithdrawing) &&
      isRemainingValue(inputValueAsBigNumber);

    if (hasRemainingSymbol) {
      return '> ';
    }

    const hasApproximationSymbol =
      (isWithdrawing &&
        maxSelected &&
        new DefiUtils(inputValue).isGreaterThan(0)) ||
      (isAddingCollateral &&
        maxSelected &&
        new DefiUtils(inputValue).isGreaterThan(0)) ||
      (isRemovingCollateral &&
        maxSelected &&
        new DefiUtils(inputValue).isGreaterThan(0) &&
        !hasTakenBorrows);

    if (hasApproximationSymbol) {
      return '≈ ';
    }

    return '';
  }, [
    hasTakenBorrows,
    inputValue,
    inputValueAsBigNumber,
    isAddingCollateral,
    isCollateralying,
    isRemovingCollateral,
    isWithdrawing,
    maxSelected,
  ]);

  const floatMinValue = new DefiUtils('1')
    .dividedBy(`1e${decimals}`)
    .toString();

  const currencyInputValue =
    // decimals < H_TOKEN_DECIMALS &&
    isRemainingValue(inputValueAsBigNumber) &&
    maxSelected
      ? floatMinValue
      : inputValue;

  const recommendedMaxRemovableCollateralBalance = useMemo(() => {
    const maxRemoveCollateralUSD = new DefiUtils(
      borrowLimitUSDWithoutSimulate || 0,
    )
      .multipliedBy(MAX_RECOMMENDED_BORROW_LIMIT_MARGIN)
      .minus(borrowBalanceUSD || 0);

    const collateralEquivalentToBorrowLimitUSD = new DefiUtils(
      maxRemoveCollateralUSD.isGreaterThan(0) ? maxRemoveCollateralUSD : 0,
    ).dividedBy(new DefiUtils(collateralFactor).dividedBy(DefiUtils.WAD));

    const maxRemoveCollateralUnderlyingAmount = new DefiUtils(
      collateralEquivalentToBorrowLimitUSD,
    )
      .dividedBy(priceUSD)
      .toString();

    const result = new DefiUtils(
      maxRemoveCollateralUnderlyingAmount,
    ).isLessThanOrEqualTo(
      new DefiUtils(collateralUnderlyingBalanceFullDecimals),
    )
      ? maxRemoveCollateralUnderlyingAmount
      : collateralUnderlyingBalanceFullDecimals;

    return result;
  }, [
    borrowLimitUSDWithoutSimulate,
    borrowBalanceUSD,
    collateralFactor,
    priceUSD,
    collateralUnderlyingBalanceFullDecimals,
  ]);

  const totalSupplyUnderlying = useMemo(
    () =>
      new DefiUtils(accountBalances.underlyingCollateral)
        .plus(new DefiUtils(accountBalances.underlyingHTokenWallet))
        .toString(),
    [
      accountBalances.underlyingCollateral,
      accountBalances.underlyingHTokenWallet,
    ],
  );

  const totalSupplyUnderlyingFullDecimals = useMemo(
    () =>
      new DefiUtils(totalSupplyUnderlying).toFullDecimals(decimals).toString(),
    [decimals, totalSupplyUnderlying],
  );

  const totalBoosterAPY = useMemo(() => {
    const calculatedBooster = new DefiUtils(
      boosterAccount[assetKey]?.totalBoosterApy || 0,
    );

    return calculatedBooster.toSafeString();
  }, [boosterAccount, assetKey]);

  const boosterApy = useMemo(() => {
    return (
      (isLoggedIn
        ? boosterAccount[assetKey]?.accountBoosterAPYWithPenalty
        : boosterAccount[assetKey]?.totalBoosterApy) || '0'
    );
  }, [boosterAccount, assetKey]);

  const totalSupplyAPY = useMemo(() => {
    if (assetKey === MARKET_KEY.sEGLD && showLiquidStakingAPY) {
      return getTotalSupplyAPYByMarketLiquidity({
        accountBalance: '0',
        // accountBalance: accountBalances.underlyingWallet, // aca_aca:fix
        hTokenExchangeRate,
        hTokenAccountBalance: accountBalances.hTokenWallet,
        collateralBalance: accountBalances.collateral,
        liquidStakingAPY,
        supplyAPY,
        rewardsTokensAPY: new DefiUtils(rewardsTokensAPY)
          .plus(boosterApy)
          .toString(),
      });
    }

    if (assetKey === MARKET_KEY.sWTAO && showLiquidStakingTaoAPY) {
      return getTotalSupplyAPYByMarketLiquidity({
        accountBalance: '0',
        // accountBalance: accountBalances.underlyingWallet, // aca_aca:fix
        hTokenExchangeRate,
        hTokenAccountBalance: accountBalances.hTokenWallet,
        collateralBalance: accountBalances.collateral,
        liquidStakingAPY: liquidStakingTaoAPY,
        supplyAPY,
        rewardsTokensAPY: new DefiUtils(rewardsTokensAPY)
          .plus(boosterApy)
          .toString(),
      });
    }

    const totalSupplyAPYByMarket = getTotalSupplyAPYByMarket({
      totalSupplyUnderlying: totalSupplyUnderlyingFullDecimals,
      collateralUnderlyingBalance: collateralUnderlyingBalanceFullDecimals,
      supplyAPY: String(supplyAPY),
      rewardsTokensAPY: new DefiUtils(rewardsTokensAPY)
        .plus(boosterApy)
        .toString(),
    }).toString();

    return totalSupplyAPYByMarket;
  }, [
    assetKey,
    showLiquidStakingAPY,
    showLiquidStakingTaoAPY,
    totalSupplyUnderlyingFullDecimals,
    collateralUnderlyingBalanceFullDecimals,
    supplyAPY,
    rewardsTokensAPY,
    boosterApy,
    hTokenExchangeRate,
    accountBalances.hTokenWallet,
    accountBalances.collateral,
    liquidStakingAPY,
    liquidStakingTaoAPY,
  ]);

  const showEightyPercentButton = useMemo(() => {
    return (
      isCollateralying &&
      isRemovingCollateral &&
      hasTakenBorrows &&
      !new DefiUtils(collateralUnderlyingBalanceFullDecimals).isEqualTo(
        recommendedMaxRemovableCollateralBalance,
      )
    );
  }, [
    collateralUnderlyingBalanceFullDecimals,
    hasTakenBorrows,
    isCollateralying,
    isRemovingCollateral,
    recommendedMaxRemovableCollateralBalance,
  ]);

  const [hasFocus, toggleFocus] = useToggle();
  const md = useMediaQuery('(min-width: 768px)');

  return (
    <PopupBed close={false}>
      <div className='font-semibold leading-tight tracking-normal'>
        <PopupHead>
          <LabeledImage
            name={ASSET_ALT_NAME[assetKey] ?? name}
            path={logo.normal}
            night={logo.nightMode}
          />
        </PopupHead>
        <>
          <div className='relative mx-2.5 my-2'>
            <div>
              <div className='relative'>
                <input type='text' style={{ display: 'none' }} />
                <Hint
                  autoReplace
                  unvisible={!maxSelected}
                  content={
                    <span className='text-[8px] md:text-[10px]'>
                      {t('maximum-amount-is-selected')}
                    </span>
                  }
                  placement='top-center'
                  block
                >
                  <CurrencyInput
                    data-testid='supplyInput'
                    data-testlimitvalue={new DefiUtils(
                      maxRequestAmounts[selectedTab].limitAsBigNumber,
                    )
                      .toFullDecimals(decimals)
                      .toString()}
                    data-testmaxvalue={new DefiUtils(
                      maxRequestAmounts[selectedTab].setMaxValueAsBigNumber,
                    )
                      .toFullDecimals(decimals)
                      .toString()}
                    prefix={prefix}
                    autoFocus={false}
                    className={classNames(
                      'w-full max-w-[442px]',
                      'p-4 px-[70px]',
                      'text-center text-[28px] font-normal leading-tight placeholder:text-[#6A6A8C] disabled:text-[#6A6A8C] dark:text-indigo-100 placeholder:dark:text-[#6A6A8C] disabled:dark:text-[#6A6A8C]',
                      'placeholder:text-current',
                      maxSelected &&
                        'pointer-events-none !cursor-not-allowed !text-[#6A6A8C] !opacity-100',
                      'outline-none',
                      'bg-transparent',
                    )}
                    placeholder='0'
                    onFocus={toggleFocus}
                    onBlur={toggleFocus}
                    suffix={
                      !hasFocus && !parseFloat(currencyInputValue)
                        ? ` ${currency}`
                        : ''
                    }
                    value={currencyInputValue}
                    autoComplete='off'
                    aria-autocomplete='none'
                    decimalsLimit={decimals}
                    groupSeparator=','
                    decimalSeparator='.'
                    allowNegativeValue={false}
                    // disabled={maxSelected}
                    onValueChange={handleInputValueChange}
                    inputMode='text'
                  />
                </Hint>
                <div className='absolute right-3 top-1/2 grid -translate-y-1/2 place-items-end'>
                  <Hint
                    content={
                      <span className='text-[8px] md:text-[10px]'>
                        {isCollateralying && !isAddingCollateral
                          ? t('set-maximum-recommended-by-the-protocol')
                          : t('set-maximum')}
                      </span>
                    }
                    placement={md ? 'top-center' : 'top-end'}
                  >
                    <CurrencyInputButton
                      onClick={handleSetMaxAmount}
                      selected={maxSelected}
                      borderLess={showEightyPercentButton && !maxSelected}
                    >
                      {showEightyPercentButton ? (
                        <div>
                          80% <br />
                          {t('limit')}
                        </div>
                      ) : (
                        <span>{t('max')}</span>
                      )}
                    </CurrencyInputButton>
                  </Hint>
                </div>
              </div>
              <div className='mt-3 flex justify-between'>
                <div className='EstimatedPrice'>
                  <div className='text-xs font-semibold dark:text-white'>
                    {t('estimated-price')}
                  </div>
                  <div className='flex items-center gap-1 text-xs text-[#6A6A8C] dark:text-[#625E8F]'>
                    {`${nFormatter(
                      inputPriceHTokenFullDecimals,
                      3,
                    )} ${hTokenCurrency}`}
                    <ApproEqualIcon className='mt-0.5' />
                    {`$${formatNumber(inputPriceUSD, 2)}`}
                  </div>
                </div>
                {isCollateralying && (
                  <div className='flex gap-3.5 self-start'>
                    <CRUDButton
                      label={t('add')}
                      action='add'
                      isActive={collateralOption === COLLATERAL_MODE.ADD}
                      onClick={() => setCollateralOption(COLLATERAL_MODE.ADD)}
                      data-testid='tab:addCollateral'
                    />
                    <CRUDButton
                      label={t('remove')}
                      action='remove'
                      isActive={collateralOption === COLLATERAL_MODE.REMOVE}
                      onClick={() =>
                        setCollateralOption(COLLATERAL_MODE.REMOVE)
                      }
                      data-testid='tab:removeCollateral'
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>

        <>
          <div>
            <Tabber
              onChange={onTabToggle}
              tabs={[...SUPPLY_TABS].map((key) => t(key.toLowerCase()))}
              defaultSelected={getTabIndex(selectedTab)}
              customTheme={(tabIdx) => {
                if (Number.isInteger(tabIdx))
                  return 'text-[#6431B7] dark:text-[#8C80D4] border-current dark:border-current';
                else
                  return 'text-[#6A6A8C] dark:text-[#625E8F] border-[#E2DFFB] dark:border-[#3F3B76]';
              }}
            >
              <div className='bg-[#FCFAFF] p-5 pb-3 dark:bg-[#292651]'>
                {/* Rate */}
                <>
                  <div className='pb-4'>
                    <div className='text-xs dark:text-indigo-100'>
                      {t('supply-rates')}
                    </div>
                    <div className='mt-2 flex items-center justify-between px-1 dark:text-white'>
                      <div className='flex items-center gap-2'>
                        <div>
                          <img className='h-[36px]' src={logo.normal} alt='' />
                        </div>
                        <div className='text-lg'>{t('supply-apy')}</div>
                      </div>
                      <div>{`${supplyAPY}%`}</div>
                    </div>
                  </div>
                </>

                {/* Rewards */}
                <div className='space-y-2'>
                  {assetKey === MARKET_KEY.sEGLD && showLiquidStakingAPY && (
                    <SEGLDLiquidStakingApyPercentage
                      token={MARKET_KEY.sEGLD}
                      apy={liquidStakingAPY}
                    />
                  )}

                  {assetKey === MARKET_KEY.sWTAO && showLiquidStakingTaoAPY && (
                    <SEGLDLiquidStakingApyPercentage
                      token={MARKET_KEY.sWTAO}
                      apy={liquidStakingTaoAPY}
                    />
                  )}

                  {rewardsSupplyToken.length > 0 ||
                  new DefiUtils(totalBoosterAPY).isGreaterThan(0) ||
                  (assetKey === MARKET_KEY.sEGLD && showLiquidStakingAPY) ||
                  (assetKey === MARKET_KEY.sWTAO && showLiquidStakingTaoAPY) ? (
                    <div className='-mx-1 flex flex-col rounded-[8px] bg-[#D9D7FB] p-2 pt-1 text-sm dark:bg-[#4A45A3] dark:text-white'>
                      <div className='pl-2'>
                        {rewardsSupplyToken.map((rewardTokenItem) => (
                          <div
                            key={rewardTokenItem.batchId}
                            className='flex items-center gap-3.5 py-[3px]'
                          >
                            <div className='rounded-full border border-[#9097D9] dark:border-[#9097D9]'>
                              <img
                                src={rewardTokenItem.logo}
                                alt={`Logo of ${rewardTokenItem.tokenId}`}
                                className='h-5 w-5'
                              />
                            </div>
                            <span>{t('rewards-apy')}</span>
                            <span className='ml-auto'>
                              {formatNumber(rewardTokenItem.apy)}%
                            </span>
                          </div>
                        ))}

                        {new DefiUtils(totalBoosterAPY).isGreaterThan(0) && (
                          <div className='flex items-center gap-3.5 py-[3px]'>
                            <div className='rounded-full border border-[#9097D9] dark:border-[#9097D9]'>
                              <img
                                src={
                                  TOKEN_LOGO_MAP[
                                    boosterAccount[assetKey]?.reward.symbol
                                  ]?.normal
                                }
                                alt={`Logo of ${boosterAccount[assetKey]?.reward.symbol}`}
                                className='h-5 w-5'
                              />
                            </div>
                            <span>{t('booster-apy', 'Booster APY')}</span>
                            <span className='ml-auto'>
                              {formatNumber(totalBoosterAPY)}%
                            </span>
                          </div>
                        )}
                      </div>

                      <div className='ml-11 flex h-7 items-center'>
                        <div className='flex w-fit items-center gap-1'>
                          {t('net-apy')}
                          <Hint
                            delayEnter={0}
                            placement={md ? 'top-center' : 'top-center'}
                            content={
                              <NetAPYTooltipContent
                                parts={[
                                  ...((assetKey === MARKET_KEY.sEGLD &&
                                    showLiquidStakingAPY) ||
                                  (assetKey === MARKET_KEY.sWTAO &&
                                    showLiquidStakingTaoAPY)
                                    ? ['Liquid Staking']
                                    : []),
                                  'Supply',
                                  'Rewards',
                                ]}
                              />
                            }
                          >
                            <InfoIcon
                              className='text-[#7A77A5] dark:text-[#A29ED6]'
                              strokeWidth={1.3}
                              width={12}
                            />
                          </Hint>
                        </div>
                        <span className='grow'></span>
                        <span>{formatNumber(totalSupplyAPY, 3)}%</span>
                      </div>
                      <div className='min-h-[28px] rounded-md bg-[#746FC0] px-2 py-1.5 text-center text-xs text-[#fff] dark:bg-[#716BE0]'>
                        {t('only-rewards-tokens-as-collateral')}
                      </div>
                    </div>
                  ) : (
                    <div className='py-5'></div>
                  )}
                </div>

                {/* Borrow Limit */}
                <>
                  <div className='mt-3'>
                    <span className='text-xs dark:text-indigo-100'>
                      {t('borrow-limits')}
                    </span>
                    <div className='mt-2 flex items-center justify-between border-b-2 border-current py-2 pt-0 text-sm leading-tight text-[#6A6A8C] dark:border-[#625E8F]/50 dark:text-[#625E8F]'>
                      <div>{t('borrow-limit')}</div>
                      <div className='flex items-center justify-center gap-1'>
                        <div className='flex items-center gap-1'>
                          {`$${formatNumber(borrowLimitUSDWithoutSimulate, 2)}`}
                          {isValidAmountRequest && (
                            <>
                              <FutureStatsRightArrow />
                              {`$${formatNumber(
                                nextBorrowLimitAmountUSD as number,
                                2,
                              )}`}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='flex items-center justify-between py-2 text-sm leading-tight text-[#6A6A8C] dark:text-[#625E8F]'>
                      <div>{t('borrow-limit-used')}</div>
                      <div className='flex items-center gap-1'>
                        {`${formatNumber(
                          getBorrowLimitUsedPercent(
                            borrowBalanceUSD,
                            borrowLimitUSDWithoutSimulate,
                          ),
                          2,
                        )}%`}
                        {isValidAmountRequest && (
                          <>
                            <FutureStatsRightArrow />
                            {`${formatNumber(
                              nextBorrowLimitUsedPercentage as number,
                              2,
                            )}%`}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>

                {/* Action Button */}
                <div className='mt-3 flex gap-1'>
                  <div className='flex-1'>
                    <Hint
                      autoReplace
                      content={
                        <span className='text-[8px] md:text-[10px]'>
                          {hasPendingTransactions
                            ? t('translation:wait-tx-inprogress')
                            : ''}
                        </span>
                      }
                      placement={md ? 'top-center' : 'top-end'}
                      className='w-full'
                      unvisible={!hasPendingTransactions}
                    >
                      <TransactionButton
                        onClick={handleButtonClick}
                        text={actionButton.label}
                        disabled={actionButton.disabled}
                      />
                    </Hint>
                  </div>
                </div>

                {/* Bottom Note */}
                <div className='mt-3.5 space-y-1.5 text-sm text-[#6A6A8C] dark:text-[#625E8F]'>
                  {footerNotes[selectedTab].map(({ label, value }) => {
                    return (
                      <div key={label} className='flex justify-between'>
                        <span>{label}</span>
                        <span>{`${toFormattedDecimalNum(
                          value,
                          value == '0' ? 2 : decimals,
                        )} ${currency}`}</span>
                      </div>
                    );
                  })}
                  {isWithdrawing && <div>{t('non-collateral-liquidity')}</div>}

                  <OperationFormBottomAlerts />
                </div>
                {sendRebalance && (
                  <div className='GreenAlert mt-1 flex min-h-[34px] w-full items-center justify-center rounded-[6px] bg-[#BDF9DF]'>
                    <div className='flex max-w-[320px] items-start gap-0.5 text-[12px] leading-[15px] text-[#3E9179]'>
                      <img
                        src='https://cdn.app.hatom.com/images/green-info.svg'
                        alt='green-info'
                        className='-translate-x-[1px] translate-y-[1.5px]'
                      />
                      <div className='flex w-full flex-col'>
                        <span className='translate-x-[0.3px] text-center'>
                          You'll need to sign two transactions to rebalance your
                        </span>
                        <span className='-translate-x-[6px] text-center'>
                          position within the HTM Booster.
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Tabber>
          </div>
        </>
      </div>
    </PopupBed>
  );
};

export default SupplyPopup;
